import { ReactElement } from "react"
import { RouteData } from "src/model"
import access from "./localAccess"
import { dashboardRoutesData } from 'src/provider/config/constant'
import * as XLSX from 'xlsx';

const routeData = (path: string, name: string, component: ReactElement<any, any>): RouteData => {
    return { 
        routeProps: {
            path,
            element: component
        },
        name
    }
}

const toTitleCase = (text: string) => {
    text = text.toLowerCase()
    return text[0].toUpperCase() + text.substring(1, text.length)
}

const splitCamelCase = (text: string, isTitleCase?: boolean) => {
    let result = text.replace(/([a-z])([A-Z])/g, '$1 $2')
    result = isTitleCase ? result[0].toUpperCase() + result.substring(1) : result 
    return result
}

const getPageTitle = () => {
    let route = toTitleCase(window.location.href.split('/').pop()!)
    let pageTitle = route ? route.split('-').join(' ') : 'Hydra'
    return pageTitle
}

export const erroMessage = (text: any) => {
    const isOnline = access.getInternetStatus()
    return !isOnline ? 'No Internet Connection' : `Message: ${text || 'An error occured'}`
}

export const requestMessage = (resp: any, text = '') => {
    return resp.message || text || 'Something went wrong'
}

export const catchErrMsg = (err: any) => {
    let message = err?.response?.data?.message || 'Something went wrong, Please try again later. If problem persist kindly contact our support.'
    
    //if (err.response?.status === 500) message = 'Something went wrong (Server)'
    if (err.response?.data?.errors) message = retrieveAPIValidationMsg(err.response.data.errors)

    if (err.response?.status === 401) {
        access.getLogoutHandler()()
    }
    return message 
}

export const loglite = (data: any, description?: string) => {
    if (description) console.log(`====== ${description} =======`)
    console.log(data)
}

export const moneyFormat = (val: number|string, twoDecimalPlace?: boolean) => {
    var formatter = new Intl.NumberFormat('en-NG', {
        currency: 'NGN',
        minimumFractionDigits: twoDecimalPlace ? 2 : 0,
        maximumFractionDigits: 2
    })
    return formatter.format(Number(val))
}

const retrieveAPIValidationMsg = (errorObj: any) => {
    const errorProperties = Object.keys(errorObj)
    const message = errorObj[errorProperties[0]][0]

    return message
}

export const handleTypeNumberControl = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/)
    let isValidCombo = false
    const ctrl = e.ctrlKey ? e.ctrlKey : ((e.which === 17) ? true : false); 
    const cmd = e.metaKey ? true : false
    
    if ((e.which == 86 && ctrl) || (e.which == 67 && ctrl) || (e.which == 88 && ctrl)) isValidCombo = true
    if ((e.which == 86 && cmd) || (e.which == 67 && cmd) ||  (e.which == 88 && cmd)) isValidCombo = true

    if (e.which === 38 || e.which === 40 || (!e.key.match(regex) && !isValidCombo)) {
        e.preventDefault();
    }
}

export const validateRouteAccess = (route: string, userType: string) => {
    const result = {isValid: false, defaultRoute: '', category: ''}
    const routesData = dashboardRoutesData[userType as keyof typeof dashboardRoutesData]

    for (let item of routesData) {
        if (item.path === route) {
            result.isValid = true
            result.category = item.menuCategory
            break;
        }
    }

    if (!result.isValid) {
        result.defaultRoute = routesData[0].path
        result.category = routesData[0].menuCategory
    }

    return result
}

const getRoleName = (roleCode: string) => {
    const role = {
        Admin: 'Inst. Admin',
        User: 'Inst. User',
        SA: 'Qore Admin'
    }

    return role[roleCode as keyof typeof role] || ''
}

const downloadReportXLS = (data: any, fileName = 'downloaded-report') => {
    const convertArrayToTable =  async (apiArray: Array<any>, fileName: string) => {
        //use keys from the first array object to form table column headers
        const tableHeaders = `<tr>${Object.keys(apiArray[0]).map(key =>   `<td>${key}</td>`).join('')}</tr>`;
        //now loop through all array objects to form table rows
        const tableRows = apiArray.map(obj =>
           [`<tr>
              ${Object.keys(obj).map(key => `<td>${obj[key] === null ||    obj[key] === '' ? '' : obj[key]}</td>`).join('')}
           <tr/>`]).join('');
        const table = `<table>${tableHeaders}${tableRows}</table>`.trim();
     
     
        const xmlTable = createXMLTable(table, fileName);
        const downloadURL = createFileUrl(xmlTable);
        downloadFile(downloadURL, fileName);
    }

    const createXMLTable = (table: string, fileName: string) => {
        const xmlTable = `
              <html xmlns:o="urn:schemas-microsoft-com:office:office xmlns:x="urn:schemas-microsoft-com:office:excel"
             xmlns="http://www.w3.org/TR/REC-html40"
              >
                 <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
                 <head>
                    <xml>
                      <x:ExcelWorkbook>
                          <x:ExcelWorksheets>
                              <x:ExcelWorksheet>
                                  <x:Name>${fileName}</x:Name>
                                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                              </x:ExcelWorksheet>
                          </x:ExcelWorksheets>
                      </x:ExcelWorkbook>
                    </xml>
                 </head>
                 <body>
                   ${table}
                 </body>
              </html> `
        return xmlTable;
    }

    const createFileUrl = (xmlTable: string) => {
        const tableBlob = new Blob([xmlTable], {type: 'application/vnd.ms-excel;base64,' });
        const downloadURL = URL.createObjectURL(tableBlob);
        return downloadURL;
    }

    const downloadFile = (downloadURL: string, fileName: string) => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = fileName;
        downloadLink.href = downloadURL;
        downloadLink.click();
    }

    convertArrayToTable(data, fileName)
}

const downloadReportCSV = (data: any[], fileName: string) => {
    function convertArrayOfObjectsToCSV(data: any) {
        const csvRows = [];
        
        // Get headers from the first object
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));
    
        // Iterate over each object in the array
        for (const obj of data) {
            const values = headers.map(header => {
                const value = obj[header];
                // Handle cases where value might contain commas
                return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
            });
            csvRows.push(values.join(','));
        }
    
        // Combine rows into a single CSV string
        return csvRows.join('\n');
    }
    
    function downloadCSV(csvData: any, fileName: string) {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
       
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                // For other browsers, fallback to data URI
                const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
                const encodedUri = encodeURI(csvContent);
                window.open(encodedUri, '_blank');
            }
        
    }
    
    const csv = convertArrayOfObjectsToCSV(data);
    downloadCSV(csv, `${fileName}.csv`);
}

const reportColumns = (reportName: string) => {
    const columns = {
        INSTITUTIONS_REPORT: [
            'name', 'institutionCode', 'id', 'alias', 'correspondencePhone', 'correspondenceEmail', 'setupDate'
        ],
        USERS_REPORT: [
            'id', 'firstName', 'lastName', 'email', 'username', 'emailConfirmed', 'role', 'creationDate', 'isPasswordChanged'
        ]
    }

    return columns[reportName as keyof typeof columns] || []
}

const reactSelectStyles = () => {
    return {
        input: (style: any) => ({
            ...style,
            color: '#344054'
        }),
        control: (base: any, state: any) => ({
          ...base,
          background: "transparent",
          height: '5rem',
          border: 0,
          color: '#344054',
          borderColor: '#D0D5DD',
          boxShadow: 'none',
          "&:hover": {
            background: "transparent",
            borderColor: 'transparent'
          }
        }),
        option: (style: any, state: any) => {
            let color = '#000'
            if (state.isSelected) {
                color = "#fff";
            }

            return {
                ...style,
                color,
                paddingTop: '0.5rem'
            }
        },
        singleValue: (style: any) => ({
            ...style,
            color: '#344054'
        }),
        menu: (base: any) => ({
          ...base,
          borderRadius: 0,
          marginTop: 0,
          margin: 0,
          padding: 0
        }),
        menuList: (base: any) => ({
          ...base,
          padding: 0
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    };
}

export default {
    routeData,
    toTitleCase,
    getPageTitle,
    requestMessage,
    catchErrMsg,
    erroMessage,
    splitCamelCase,
    moneyFormat,
    validateRouteAccess,
    getRoleName,
    downloadReportCSV,
    downloadReportXLS,
    reportColumns,
    reactSelectStyles
}