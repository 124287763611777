import './style.scss'
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AppInfoContext from "src/provider/state-manager/appInfoProvider"
import { IoIosArrowBack, IoIosInformationCircleOutline } from "react-icons/io"
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, GridContainer } from "src/style"
import utility from "src/utils/utility"
import helpers from "src/utils/helpers"
import { Approve_DeclineUpgradeRequest, DocumentPreview } from 'src/popup'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const AccountUpgradeRequestDetails: React.FC<any> = () => {
    const navigate = useNavigate()
    const {info: {selectedAccountUpgradeRequest}} = useContext(AppInfoContext)
    const [upgradeData, setUpgradeData] = useState(selectedAccountUpgradeRequest)
    const [docUrl, setDocUrl] = useState('')
    const [view, setView] = useState('')

    useEffect(() => {
        if (selectedAccountUpgradeRequest?.id) {
            setUpgradeData(selectedAccountUpgradeRequest)
        }
    }, [selectedAccountUpgradeRequest])

    function handleDocumentPreview (url: string) {
        setDocUrl(url)
        setView('doc-preview')
    }
    
    return (
        <>
            <CustomContainer 
                className='profile-container'
            >
                <CustomContainer>
                    <FlexRow 
                        justifyContent='flex-start' gap='1'
                        width='auto' sizeUnit=''
                        onClick={() => navigate(-1)}
                    >
                        <AppSpan textSize='2' cursor='pointer' >
                            <IoIosArrowBack />
                        </AppSpan>
                        <AppText 
                            textSize='3' fontWeight='600' color='#101828' cursor='pointer'
                        >
                            Upgrade Details ({upgradeData?.customerName || upgradeData?.accountNumber})
                        </AppText>
                    </FlexRow>

                    <FlexRow
                        bgColor='#ffffff' minHeight='10.6' 
                        alignItems='flex-start' justifyContent='flex-start'
                        radius='0.8' padding='2' topMargin="2"
                        gap='4' wrap='wrap' borderColor='#EAECF0'
                        shadow='0px 1px 2px 0px #1018280F' 
                    >
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Customer Name</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData?.customerName}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Customer ID</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData?.customerID}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Account Number</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData?.accountNumber}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Status</AppText>
                            <GridContainer 
                                width='11' sizeUnit="" 
                                minHeight='2.2' topMargin="0.5"
                                bgColor={upgradeData.status === 'COMPLETED' ? '#ECFDF3' : upgradeData.status === 'REJECTED' ? '#FFF5F5' : '#FEF0C7'} 
                                topPadding='0' bottomPadding='0' 
                                padding='0.8' radius='1.6'
                            >
                                <AppSpan 
                                    color={upgradeData.status === 'COMPLETED' ? '#027A48' : upgradeData.status === 'REJECTED' ? '#DC2525' : '#B54708'} 
                                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                                >
                                    {upgradeData.status}
                                </AppSpan>
                            </GridContainer>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Is Upgraded</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData.isUpgraded ? 'TRUE' : 'FALSE'}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Request Date</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData?.requestedAt ? helpers.handleDate(upgradeData.requestedAt) : upgradeData?.requestedAt}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20'>
                            <AppText color='#333542' fontWeight='600' textSize="1.6">Upgraded Date</AppText>
                            <AppSpan color='#333542' fontWeight='300'>{upgradeData?.upgradedAt ? helpers.handleDate(upgradeData.upgradedAt) : 'null'}</AppSpan>
                        </CustomContainer>
                    </FlexRow>

                    <CustomContainer topMargin="2" bottomPadding="1.5">
                        <FlexRow gap="1" justifyContent="flex-start">
                            <IoIosInformationCircleOutline fill="#0D968F" fontSize='2.2rem' />
                            <AppSpan textSize="2" fontWeight="600" color="#0D968F">Approval Request</AppSpan>
                        </FlexRow>

                        <CustomContainer
                            bgColor='#ffffff' minHeight='20.6' 
                            radius='0.8' padding='2'
                            borderColor='#EAECF0' topMargin="2"
                            shadow='0px 1px 2px 0px #1018280F' 
                        >
                            <FlexRow
                                alignItems='flex-start' justifyContent='flex-start'
                                gap='4' wrap='wrap'
                            >
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">BVN</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.bvn}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">NIN</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.nin}</AppSpan>
                                </CustomContainer>
                                {/* <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">ID Type</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.idType}</AppSpan>
                                </CustomContainer> */}
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">ID Number</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.idNumber}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">Bill Type</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.billType}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">Address</AppText>
                                    <AppSpan color='#333542' fontWeight='300'>{upgradeData?.address}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">Signature</AppText>
                                    { upgradeData?.signatureUrl &&
                                        <CustomContainer 
                                            width='14' height='7' 
                                            radius="0.9" topMargin="0.6"
                                            className="upgrade-doc-container"
                                        >
                                            <img 
                                                src={upgradeData.signatureUrl} className='ads-img'
                                                style={{objectFit: 'cover', objectPosition: 'left'}}  
                                            />
                                            <AbsoluteContainer 
                                                height="full"
                                                width="100" sizeUnit="%"
                                                top="0" left="0"
                                            >
                                                <GridContainer 
                                                    height="100" hUnit="%" bgColor="rgba(0,0,0, 0.3)"
                                                    onClick={() => handleDocumentPreview(upgradeData.signatureUrl)}
                                                >
                                                    <AppText 
                                                        textSize="1.6" color="#ffffff" 
                                                        fontWeight="600" cursor='pointer'
                                                    >
                                                        View
                                                    </AppText>
                                                </GridContainer>
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    }
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">{upgradeData?.idType || 'ID Card'}</AppText>
                                    { upgradeData?.validIDUrl &&
                                        <CustomContainer 
                                            width='14' height='7' 
                                            radius="0.9" topMargin="0.6"
                                            className="upgrade-doc-container"
                                        >
                                            <img 
                                                src={upgradeData.validIDUrl} className='ads-img'
                                                style={{objectFit: 'cover', objectPosition: 'left'}}  
                                            />
                                            <AbsoluteContainer 
                                                height="full"
                                                width="100" sizeUnit="%"
                                                top="0" left="0"
                                            >
                                                <GridContainer 
                                                    height="100" hUnit="%" bgColor="rgba(0,0,0, 0.3)"
                                                    onClick={() => handleDocumentPreview(upgradeData.validIDUrl)}
                                                >
                                                    <AppText 
                                                        textSize="1.6" color="#ffffff" 
                                                        fontWeight="600" cursor='pointer'
                                                    >
                                                        View
                                                    </AppText>
                                                </GridContainer>
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    }
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">Proof of Address</AppText>
                                    { upgradeData?.utilityUrl &&
                                        <CustomContainer 
                                            width='14' height='7' 
                                            radius="0.9" topMargin="0.6"
                                            className="upgrade-doc-container"
                                        >
                                            <img 
                                                src={upgradeData.utilityUrl} className='ads-img'
                                                style={{objectFit: 'cover', objectPosition: 'left'}}  
                                            />
                                            <AbsoluteContainer 
                                                height="full"
                                                width="100" sizeUnit="%"
                                                top="0" left="0"
                                            >
                                                <GridContainer 
                                                    height="100" hUnit="%" bgColor="rgba(0,0,0, 0.3)"
                                                    onClick={() => handleDocumentPreview(upgradeData.utilityUrl)}
                                                >
                                                    <AppText 
                                                        textSize="1.6" color="#ffffff" 
                                                        fontWeight="600" cursor='pointer'
                                                    >
                                                        View
                                                    </AppText>
                                                </GridContainer>
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    }
                                </CustomContainer>
                                <CustomContainer width='20'>
                                    <AppText color='#333542' fontWeight='600' textSize="1.6">Passport</AppText>
                                    { upgradeData?.passportUrl &&
                                        <CustomContainer 
                                            width='14' height='7' 
                                            radius="0.9" topMargin="0.6"
                                            className="upgrade-doc-container"
                                        >
                                            <img 
                                                src={upgradeData.passportUrl} className='ads-img'
                                                style={{objectFit: 'cover', objectPosition: 'left'}}  
                                            />
                                            <AbsoluteContainer 
                                                height="full"
                                                width="100" sizeUnit="%"
                                                top="0" left="0"
                                            >
                                                <GridContainer 
                                                    height="100" hUnit="%" bgColor="rgba(0,0,0, 0.3)"
                                                    onClick={() => handleDocumentPreview(upgradeData.passportUrl)}
                                                >
                                                    <AppText 
                                                        textSize="1.6" color="#ffffff" 
                                                        fontWeight="600" cursor='pointer'
                                                    >
                                                        View
                                                    </AppText>
                                                </GridContainer>
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    }
                                </CustomContainer>
                            </FlexRow>

                            { !upgradeData?.isUpgraded &&
                                <FlexRow 
                                    justifyContent='flex-end'
                                    gap='1.5' topMargin='2.5' 
                                >
                                    <Button
                                        width='8.9'
                                        fontWeight='600' height='4'
                                        hoverColor='#0D968F' borderColor='#0D968F'
                                        hoverBgColor='#fff'
                                        onClick={() => setView('approve')}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        width='8.3' bgColor='#ffffff'
                                        fontWeight='600'
                                        height='4' color='#344054'
                                        borderColor='#D0D5DD'
                                        hoverBorderColor='#0D968F'
                                        onClick={() => setView('decline')}
                                    >
                                        Decline
                                    </Button>
                                </FlexRow>
                            }
                        </CustomContainer>
                    </CustomContainer>
                </CustomContainer>
            </CustomContainer>
            {/* CUSTOM IMAGE PREVIEW LIGHTBOX */}
            {/* { view === 'doc-preview' &&
                <DocumentPreview 
                    docUrl={docUrl}
                    close={() => setView('')}
                />
            } */}
            { ['approve', 'decline'].includes(view) &&
                <Approve_DeclineUpgradeRequest 
                    type={view}
                    requestData={upgradeData}
                    close={() => setView('')}
                />
            }
            { view === 'doc-preview' &&
                <Lightbox 
                    mainSrc={docUrl}
                    onCloseRequest={() => setView('')}
                    imageTitle={docUrl}
                />
            }
        </>
    )
}

export default utility.routeData('account-upgrade-details', 'Account Upgrade Request Details', <AccountUpgradeRequestDetails/>)
