function SvgComponent(props: any) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
        <path
            d="M8 10c-2.114 0-3.994 1.02-5.19 2.604-.258.34-.386.511-.382.742a.743.743 0 00.255.512c.181.142.432.142.934.142h8.764c.502 0 .753 0 .935-.142a.743.743 0 00.255-.513c.004-.23-.125-.4-.382-.741C11.992 11.02 10.113 10 7.999 10zM8 8a3 3 0 100-6 3 3 0 000 6z"
            stroke={props.isHover ? '#0A756F' : '#344054'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        </svg>
    )
}

export default SvgComponent