import './style.scss'
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { AppSpan, AppText, Button, Circle, CustomContainer, FlexColumn, FlexRow, GridContainer, Icon } from 'src/style'
import { IoIosArrowBack } from "react-icons/io";
import ProfileBgImg from 'src/assets/img/profile-bg-img.svg'
import utility from 'src/utils/utility';
import { ChangePassword } from 'src/popup';
import { useEffect } from 'react';
import UserPics from 'src/assets/img/rhydhur_pic.jpg'
import {ReactComponent as LockIcon} from 'src/assets/img/lock-icon.svg'
import { UserTypes } from 'src/model';
import helpers from 'src/utils/helpers';

const Profile = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {decisionBox} = useContext(VisibilityContext)
    const {info: {selectedUser, userType, profileTypeAccess}} = useContext(AppInfoContext)
    const [user, setUser] = useState<any>(null)
    const [userRole, setUserRole] = useState('')
    const [accessType, setAccessType] = useState(profileTypeAccess)
    const [view, setView] = useState('')

    useEffect(() => {
        if (selectedUser) setUser(selectedUser)
    }, [selectedUser])

    useEffect(() => {
        if (profileTypeAccess) setAccessType(profileTypeAccess)
    }, [profileTypeAccess])

    useEffect(() => {
        setUserRole(userType)
    }, [userType])

    function handleUserAction (type: string) {
        let action;
        
        if (type === 'activate') action = handleRestoreProfile
        else if (type === 'reset') action = handleResetUser
        else if (['lock', 'unlock'].includes(type)) action = handleLock_UnlockUser

        decisionBox.show(
            `Are you sure you want to ${type} user?`,
            action,
            decisionBox.hide,
            `Yes, ${type}`,
            'No'
        )
    }

    async function handleLock_UnlockUser() {
        const response = !user.isLocked ? await API.lockUser(user.id) : await API.unlockUser(user.id)
        if (response) navigate(-1)
    }

    async function handleRestoreProfile() {
        const response = await API.restoreProfile(user.id)
        if (response) navigate(-1)
    }

    async function handleResetUser() {
        const response = await API.resetUserProfile(user.id)
        if (response) navigate(-1)
    }

    return (
        <>
            <CustomContainer 
                className='profile-container'
            >
                <CustomContainer>
                    <FlexRow 
                        justifyContent='flex-start' gap='1'
                        width='auto' sizeUnit=''
                        onClick={() => navigate(-1)}
                    >
                        <AppSpan textSize='2' cursor='pointer' >
                            <IoIosArrowBack />
                        </AppSpan>
                        <AppText 
                            textSize='3' fontWeight='600' color='#101828' cursor='pointer'
                        >
                            Profile
                        </AppText>
                    </FlexRow>
                    <CustomContainer 
                        maxHeight='17.6' radius='0.46'
                        height='17.6' topMargin='2'
                    >
                        <img src={ProfileBgImg} className='ads-img' style={{width: '100%', objectFit: 'cover'}} />
                    </CustomContainer>
                    <FlexRow 
                        justifyContent='space-between' topMargin='-6.5' 
                        alignItems='stretch' 
                        gap='1.5' leftPadding='2' rightPadding='2'
                        className='profile-content-wrapper'
                    >
                        <FlexColumn 
                            bgColor='#ffffff' width='20.9' minHeight='22.3' 
                            radius='0.8' padding='2'
                            className='profile-pics-container'
                        >
                            <Circle 
                                size='9.2' bgColor='#DBDBDB' bottomMargin='1.5'
                                style={{overflow: 'hidden'}}
                            >
                                <img src={user?.profilePictureUrl || UserPics} className='ads-img' />
                            </Circle>
                            <GridContainer>
                                <AppText color='#58595B' fontWeight='700'>{user?.firstName} {user?.lastName}</AppText>
                                { accessType === 'user' &&
                                    <AppSpan color='#A098AE'>{utility.getRoleName(user?.role)}</AppSpan>
                                }
                            </GridContainer>
                        </FlexColumn>
                        <FlexColumn 
                            className='profile-details-container'
                            bgColor='#ffffff' minHeight='22.3'
                            radius='0.8' padding='2' justifyContent='space-between'
                        >
                            <FlexRow 
                                justifyContent='flex-start' alignItems='flex-start'
                                gap='4' wrap='wrap'
                            >
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Full name</AppText>
                                    <AppSpan color='#A098AE'>{user?.firstName}{user?.lastName}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Email</AppText>
                                    <AppSpan color='#A098AE'>{user?.emailAddress}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Username</AppText>
                                    <AppSpan color='#A098AE'>{user?.username}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Phone number</AppText>
                                    <AppSpan color='#A098AE'>{user?.phoneNumber}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Gender</AppText>
                                    <AppSpan color='#A098AE'>{user?.gender}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Creation Date</AppText>
                                    <AppSpan color='#A098AE'>{user?.creationDate && helpers.handleDate(user?.creationDate)}</AppSpan>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText color='#58595B' fontWeight='700'>Status</AppText>
                                    <GridContainer 
                                        width='6.5' height='2.2' radius='1.6' 
                                        bgColor={!user?.isDeleted ? '#ECFDF3' : '#FFEBEA'}
                                    >
                                        <AppSpan 
                                            textSize='1.2' fontWeight='500' 
                                            color={!user?.isDeleted ? '#027A48' : '#D30202'}
                                        >
                                            {!user?.isDeleted ? 'Active' : 'Inactive'}
                                        </AppSpan>
                                    </GridContainer>
                                </CustomContainer>
                            </FlexRow>
                            <FlexRow justifyContent='flex-end' gap='1.5' wrap='wrap' topMargin='3'>
                            { (userRole === UserTypes.ADMIN && accessType === 'report') &&
                                <>
                                    <Button
                                        width='13.1' bgColor='transparent'
                                        titleSize='1.4' fontWeight='600' 
                                        borderColor='#ffffff' shadow='none'
                                        height='4' radius='0.8' color='#0A756F'
                                        bottomMargin='1' hoverColor='#0A756F'
                                        hoverBgColor='#fff' hoverBorderColor='#0A756F'
                                        onClick={() => handleUserAction(user?.isLocked ? 'unlock' : 'lock')}
                                    >
                                        <AppSpan rightMargin='0.7'>
                                            <LockIcon />
                                        </AppSpan>
                                        {user?.isLocked ? 'Unlock User' : 'Lock User'}
                                    </Button>
                                    { user?.isDeleted &&
                                        <Button
                                            width='14.1' bgColor='#E7F6F5'
                                            titleSize='1.4' fontWeight='600'
                                            height='4' radius='0.8' color='#0D968F'
                                            bottomMargin='1' hoverColor='#0D968F'
                                            hoverBgColor='#fff' hoverBorderColor='#0D968F'
                                            onClick={() => handleUserAction('activate')}
                                        >
                                            Activate User
                                        </Button>
                                    }
                                    <Button
                                        width='14.1' bgColor='#FEF3F2'
                                        titleSize='1.4' fontWeight='600'
                                        height='4' radius='0.8' color='#D30202'
                                        bottomMargin='1' hoverColor='#D30202'
                                        hoverBgColor='#fff' hoverBorderColor='#D30202'
                                        onClick={() => handleUserAction('reset')}
                                    > 
                                        Reset User
                                    </Button>
                                </>
                            }
                            { accessType === 'user' &&
                                <Button
                                    width='15.5'
                                    titleSize='1.4' fontWeight='600'
                                    height='4' radius='0.8'
                                    bottomMargin='1' hoverColor='#0D968F'
                                    hoverBgColor='#fff' borderColor='#0D968F'
                                    onClick={() => setView('change-password')}
                                >
                                    Change Password
                                </Button>
                            }
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                </CustomContainer>
            </CustomContainer>
            { view === 'change-password' &&
                <ChangePassword
                    close={() => setView('')} 
                />
            }
        </>
    )
}

export default utility.routeData('user-profile', 'User Profile', <Profile/>)