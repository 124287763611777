import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { Header, Sidebar } from 'src/component'
import ReactTooltip from 'react-tooltip'
import access from 'src/utils/localAccess'
import './dashboard.scss'
import helpers from 'src/utils/helpers'
import { appSettings, menuCategory } from 'src/provider/config/constant'
import { useIdleTimer } from 'react-idle-timer'
import { validateRouteAccess } from 'src/utils/utility'
import { ChangePassword } from 'src/popup'

export const DashboardRoutes = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {API} = useContext(ApiContext)
    const {
        info: {
            adminAuthToken, sidebarMenuType, userType, 
            profileTypeAccess, userData
        }, recoverStatesData, setInfoProperty, logout
    } = useContext(AppInfoContext)
    const [isAuth, setIsAuth] = useState(!!adminAuthToken)
    const [isDetachedPath, setIsDetachedPath] = useState(false)
    const [accessType, setAccessType] = useState(profileTypeAccess)
    const [hasSubMenu, setHasSubMenu] = useState(false)
    const [shouldChangePassword, setShouldChangePassword] = useState(false)

    // Menu categories with no sub-menu
    let noSubMenuItems = ['institutions', 'users','faqs']

    //Screens navigated to via sub-menu which should not have sidebar
    let detachedPaths = ['/dashboard/user-profile', '/dashboard/institution-details', '/dashboard/account-upgrade-details']

    useEffect(() => {
        if (userData) setShouldChangePassword(!userData.isPasswordChanged)
    }, [userData])

    useEffect(() => {
        if(profileTypeAccess) setAccessType(profileTypeAccess)
    }, [profileTypeAccess])

    useEffect(() => {
        validateRoutes()
    }, [location, userType])

    useEffect(() => {
        validateMenuRoutes()
    }, [location, sidebarMenuType])

    useEffect(() => {
        access.setNavigationAccess(navigate)
        access.setLogoutHandler(logout)
        recoverStatesData()
        handleAuth()
    }, [])

    async function handleAuth () {
        let [authToken, sessionExpiryTime, institution] = await Promise.all([
            localStorage.getItem('adminAuthToken'),
            localStorage.getItem('session-expiry-time'),
            JSON.parse(localStorage.getItem('institutionData')!)
        ])

        authToken = authToken === 'null' ? null : authToken

        if (authToken && institution?.id) API.getInstitutionById(institution.id)

        let isSessionExpire = false
        const currentDate = new Date()

        if (sessionExpiryTime) {
            if (currentDate > new Date(sessionExpiryTime)) isSessionExpire = true
        }

        setIsAuth(!!authToken)

        if (!authToken || isSessionExpire) {
            await logout()
            navigate('/')
        }
    }

    function validateRoutes() {
        if (userType) {
            const {defaultRoute, category} = validateRouteAccess(location.pathname, userType)
            if (defaultRoute) navigate(defaultRoute)
            if (category) setInfoProperty('sidebarMenuType', category)
        }
    }

    function validateMenuRoutes() {
        setIsDetachedPath(detachedPaths.includes(location.pathname))
        setHasSubMenu(!noSubMenuItems.includes(sidebarMenuType))

        const selectedMenu = menuCategory[sidebarMenuType as keyof typeof menuCategory]
        if (selectedMenu?.length && (!selectedMenu.includes(location.pathname) && (location.pathname != '/dashboard/user-profile' || accessType != 'user'))) {
            navigate(selectedMenu[0])
        }
    }

    const onAction = () => helpers.setSessionExpiryTime()
    const onActive = () => {}
    const onIdle = async () => {
        await logout()
        navigate('/')
    }

    const idleTimer = useIdleTimer({
        timeout: appSettings.INACTIVITY_TIME,
        onIdle,
        onActive,
        onAction,
        debounce: 500
    })
    
    return (
        <>
            { isAuth &&
                <div className="dashboard-container">
                    <Header/>
                    { shouldChangePassword &&
                        <ChangePassword isNewUserCheck />
                    }
                    {(!isDetachedPath && hasSubMenu) && <Sidebar/>}
                    <div className={`${(isDetachedPath || !hasSubMenu) ? 'dashboard-modules-profile' : 'dashboard-modules'}`}>
                        <Outlet/>
                        <ReactTooltip className='tooltip-style' arrowColor='#081952' multiline={true} />
                    </div>
                </div>
            }
        </>
    )
}

export default {
    routeProps: {
        path: 'dashboard',
        element: <DashboardRoutes/>
    },
    name: 'Dashboard'
}