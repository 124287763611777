import './style.scss'
import utility from "src/utils/utility"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AppInfoContext from "src/provider/state-manager/appInfoProvider"
import { AppSpan, AppText, CustomContainer, FlexRow, ScreenContainer } from "src/style"
import { IoIosArrowBack } from "react-icons/io"
import { IAuditDetails } from "src/model"

const AuditReportDetails: React.FC<any> = ({}) => {
    const navigate = useNavigate()
    const {info: {selectedAudit}} = useContext(AppInfoContext)
    const [auditDetails, setAuditDetails] = useState<IAuditDetails|any>()

    useEffect(() => {
        if (selectedAudit?.userID) {
            const dataBefore = selectedAudit.dataBefore ? JSON.parse(selectedAudit.dataBefore) : {}
            const dataAfter = selectedAudit.dataAfter ? JSON.parse(selectedAudit.dataAfter): {}
            setAuditDetails({...selectedAudit, dataBefore, dataAfter})
        }
    }, [selectedAudit])

    return (
        <CustomContainer 
            padding="2" minHeight="80" mnHUnit="vh"
            className="audit-details-container"
        >
            <CustomContainer>
                <FlexRow 
                    justifyContent='flex-start' gap='1'
                    width='auto' sizeUnit=''
                    onClick={() => navigate(-1)}
                >
                    <AppSpan textSize='2' cursor='pointer' >
                        <IoIosArrowBack />
                    </AppSpan>
                    <AppText 
                        textSize='3' fontWeight='600' color='#101828' cursor='pointer'
                    >
                        Audit Report - {auditDetails?.userID}
                    </AppText>
                </FlexRow>
                <CustomContainer topMargin='2'>
                    <AppText 
                        textSize='1.8' color='#101828' 
                        fontWeight='600'
                    >
                        Operation: {auditDetails?.action}
                    </AppText>

                    <FlexRow 
                        justifyContent='space-between' topMargin='2'
                        gap='2' alignItems='flex-start'
                        className='audit-details-content-container'
                    >
                        <CustomContainer
                            radius='0.8' bgColor='#ffffff'
                            padding='2.5' borderColor='#EAECF0'
                            minHeight='31' width='49' sizeUnit='%'
                            topPadding='2' bottomPadding='2'
                        >
                            <AppSpan textSize='1.6' color='#333542' fontWeight='700'>
                                Data Before
                            </AppSpan>
                            <FlexRow 
                                topMargin='2' gap='2' justifyContent='space-between' 
                                alignItems='flex-start' wrap='wrap'
                                className='audit-details-item-container'
                            >
                                { typeof(auditDetails?.dataBefore) === 'object' &&
                                    Object.keys(auditDetails?.dataBefore)?.map((item, index) => {
                                        return (
                                            <CustomContainer 
                                                key={index} width='auto' sizeUnit=''
                                                className='audit-details-item'
                                            >
                                                <AppSpan textSize='1.6' color='#333542' fontWeight='600'>
                                                    {item}
                                                </AppSpan>
                                                <AppText color='#333542' fontWeight='300'>
                                                    {auditDetails?.dataBefore?.[item]}
                                                </AppText>
                                            </CustomContainer>
                                        )
                                    })
                                }
                            </FlexRow>
                        </CustomContainer>
                        <CustomContainer
                            radius='0.8' bgColor='#ffffff'
                            padding='2.5' borderColor='#EAECF0'
                            minHeight='31' width='49' sizeUnit='%'
                            topPadding='2' bottomPadding='2'
                        >
                            <AppSpan textSize='1.6' color='#333542' fontWeight='700'>
                                Data After
                            </AppSpan>
                            <FlexRow 
                                topMargin='2' gap='2' justifyContent='space-between' 
                                alignItems='flex-start' wrap='wrap'
                                className='audit-details-item-container'
                            >
                                { typeof(auditDetails?.dataAfter) === 'object' &&
                                    Object.keys(auditDetails?.dataAfter)?.map((item, index) => {
                                        return (
                                            <CustomContainer 
                                                key={index} width='auto' sizeUnit=''
                                                className='audit-details-item'
                                            >
                                                <AppSpan textSize='1.6' color='#333542' fontWeight='600'>
                                                    {item}
                                                </AppSpan>
                                                <AppText color='#333542' fontWeight='300'>
                                                    {auditDetails?.dataAfter?.[item]}
                                                </AppText>
                                            </CustomContainer>
                                        )
                                    })
                                }
                            </FlexRow>
                        </CustomContainer>
                    </FlexRow>
                </CustomContainer>
            </CustomContainer>
        </CustomContainer>
    )
}

export default utility.routeData('audit-report/details', 'Audit Report Details', <AuditReportDetails/>)