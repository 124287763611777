import { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import utility from 'src/utils/utility'
import { AppText, CustomContainer, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { loginReportData, loginReportColumn } from 'src/utils/tableLayout';
import { UserTypes } from 'src/model';


const LoginAuditReport = () => {
    const {API, downloadReport} = useContext(ApiContext)
    const {info: {userType, role}} = useContext(AppInfoContext)
    const [reportData, setReportData] = useState<any>(null)
    const [report, setReport] = useState<any>(null)
    const [view, setView] = useState<string>('')
    const [user, setUser] = useState({role: '', type: ''})
    const [resetPagination, setResetPagination] = useState<boolean>(false)


    const filterDataRef = useRef({})

    useEffect(() => {
        if (userType) setUser({role: role, type: userType})
    }, [userType, role])
    
    useEffect(() => {
        filterDataRef.current = {}
        if (userType) handleFetchReport()
    }, [userType])

    async function handleFetchReport (pageNumber = 1, perPage = 10, isDownload = false) {
        const response = await API.getAdminLoginReport(pageNumber, perPage, {...filterDataRef.current, returnMax: isDownload}, userType === UserTypes.SUPER_ADMIN)
        if (response) {
            if (isDownload) {
                return downloadReport(response, 'LOGIN_AUDIT_REPORT')
            }
            setReportData(response)
            setReport(helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize: perPage}))
        }
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchReport()
        setResetPagination(!resetPagination)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' fontWeight="600" bottomMargin='1'>
                        Login Report
                    </AppText>
                    <ServerSideTable
                        tableData={report}
                        tableColumns={loginReportColumn} 
                        dataFetchMethod={handleFetchReport}
                        totalRows={reportData?.total}
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setView('report')}
                        canClear={handleClear}
                        downloadAction={() => handleFetchReport(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { view === 'report' &&
                <ReportFilter
                    type='login-report'
                    onApplyFilter={handleFetchReport}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setView('')} 
                />
            }
        </>
    )
}

export default utility.routeData('login-report', 'Login Audit Report', <LoginAuditReport/>)