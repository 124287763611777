import './style.scss'
import { useState, useContext, useEffect } from 'react';
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom';
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { AppText, Button, Circle, CustomContainer, FlexColumn, FlexRow, GridContainer, RoundImageContainer, ScreenContainer } from 'src/style';
// import { FirstAppDesign, SecondAppDesign, ThirdAppDesign } from 'src/component';
// import AppDesign1 from 'src/assets/img/app-design1.svg'
// import AppDesign2 from 'src/assets/img/app-design2.svg'
// import AppDesign3 from 'src/assets/img/app-design3.svg'
import AppDesign1 from 'src/assets/img/app-design1.png'
import AppDesign2 from 'src/assets/img/app-design2.png'
import AppDesign3 from 'src/assets/img/app-design3.png'
import { UserTypes } from 'src/model';


const AppDesign: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {userType, institutionData}} = useContext(AppInfoContext)
    const [userRole, setUserRole] = useState('')
    const [input, setInput] = useState(0)

    useEffect(() => {
        if (institutionData) setInput(institutionData.design)
        if (userType) setUserRole(userType)
    }, [institutionData, userType])

    function handleSubmit () {
        if (!input) return notifier.show('Kindly select a design')
        API.updateDesign(input)
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.9' color='#101828' fontWeight="700">Select Mobile & Internet Banking Design</AppText>
                <CustomContainer topMargin='2'>
                    {
                        Array.from(Array(3).keys()).map((item: number, index: number) => {
                            let design = index === 0 ? 1 : index === 1 ? 2 : 3
                            let component = index === 0 ? AppDesign1 : index === 1 ? AppDesign2 : AppDesign3

                            return (
                                <CustomContainer 
                                    width='100' sizeUnit='%' height='53' borderColor='#D0D5DD'
                                    shadow='0px 0.766347px 1.53269px rgba(16, 24, 40, 0.05)'
                                    radius='0.613077' topPadding='3'
                                    rightPadding='4' leftPadding='4' 
                                    bottomPadding='3' key={index}
                                    bottomMargin={index != 2 ? '4' : '0'}
                                    className='laptop-design-wrapper'
                                    onClick={() => userRole === UserTypes.ADMIN && setInput(design)}
                                >
                                    <RoundImageContainer 
                                        size="1.7" 
                                        borderColor={input === design ? '#0D968F' : '#EAECF0'}
                                        bgColor={input === design ? '#E7F6F5' : 'transparent'}
                                        rightMargin="1.5" bottomMargin='3'
                                    >
                                        { input === design && <Circle bgColor="#0D968F" size='0.8' /> }
                                    </RoundImageContainer>
                                    <FlexColumn>
                                        <GridContainer 
                                            width='80'
                                            height='40'
                                        >
                                            <img 
                                                src={component} 
                                                className='ads-img laptop-design-img'
                                            />
                                        </GridContainer>
                                    </FlexColumn>
                                </CustomContainer>
                            )
                        })
                    }
                </CustomContainer>
                { userRole === UserTypes.ADMIN &&
                    <FlexRow justifyContent='flex-end' topMargin='3'>
                        <Button
                            width='8'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='3.5' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                            leftMargin='auto' rightMargin='auto'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </FlexRow>
                }
            </ScreenContainer>
        </>
    );
}

export default utility.routeData('app-design', 'Application Design', <AppDesign/>)