import { AbsoluteContainer, AppText, Button, Circle, CustomContainer, FlexRow } from "src/style"
import { IPhone } from "src/component"
import { Logo, TopSemiCircle } from "src/assets/icons"
import PhoneHolding from "src/assets/img/hold-phone-right.png"
import { HiOutlineArrowSmRight } from 'react-icons/hi'

export const ThirdAppDesign: React.FC<any> = ({containerClass, contentClass, notchClass, imgWrapClass}) => {
    return (
        <IPhone 
            containerClass={containerClass}
            contentClass={contentClass} 
        >
             <CustomContainer height="100" hUnit="%">
                <Logo className="second-design-logo" />
                <CustomContainer leftPadding="1.5" rightPadding="1">
                    <AppText textSize="1.3" fontWeight="800" fontFamily="Gilmer-Bold">Welcome</AppText>
                    <AppText textSize='0.9' topMargin='0.5'>
                        We move financial services to your pocket. Unparallel digital 
                        banking now at your finger and you are in charge. 
                    </AppText>
                    <Button
                        width='4' topMargin="1"
                        titleSize='1.3' fontWeight='600'
                        height='2.5' radius='2'
                        hoverColor='#0D968F'
                        hoverBgColor='#ffffff' borderColor='#0D968F'
                        rightMargin='2'
                    >
                        <HiOutlineArrowSmRight style={{marginTop: '-0.5rem'}} />
                    </Button>
                </CustomContainer>
                <TopSemiCircle className="scale-top-semi-circle" />
                <AbsoluteContainer top="22" left="1">
                    <Circle 
                        size="20" bgColor="rgba(13, 150, 143, 0.07)" leftMargin="-1"
                        
                    />
                </AbsoluteContainer>
                <FlexRow 
                    width='100' sizeUnit="%" height="23" topMargin="-8" 
                    justifyContent="flex-end" className={imgWrapClass || 'third-design-img-wrap'}
                >
                    <img src={PhoneHolding} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                </FlexRow>
             </CustomContainer>
        </IPhone>
    )
}