import { useState, useContext, useEffect, useCallback } from 'react';
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { 
    AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, 
    Form, FormGroupWithIcon, ScreenContainer 
} from 'src/style';
import { explain } from 'src/utils/view'
import ReactTooltip from 'react-tooltip'
import helpers from 'src/utils/helpers';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { QoreStaffTypes } from 'src/model';


const BuildConfiguration = () => {
    const {API} = useContext(ApiContext)
    const {info: {qoreStaffType}} = useContext(AppInfoContext)
    
    const [input, setInput] = useState(
        {
        packageName:'', 
        versionName:'',
        versionCode:'',
        keystorePassword:'',
        keystoreKey:'',
        keystoreAlias:'',
        updatedAt:'',}
    )

    const [defaultDate] = !!input?.updatedAt ? new Date(input?.updatedAt)?.toISOString().split('T') : ''
    const [showPassword, setShowPassword] = useState(false);
    const [userType, setUserType] = useState('')
    const [inputsDisabled, setInputsDisabled] = useState(false);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    useEffect(() => {
        if (qoreStaffType) {
            setUserType(qoreStaffType)

            if (qoreStaffType != QoreStaffTypes.DEVELOPER) {
                setInputsDisabled(true)
            }
        }
    }, [qoreStaffType])

    const getBuildInfo = useCallback(async()=>{
        const response = await API.fetchAndroidBuildInfoByCodeOrDefault()
        if (response?.isSuccessful) {
            setInput({
                ...input,
                ...response?.data
            })
        }
    },[API, input])

    useEffect(() => {
        
       (async()=>{
        await getBuildInfo()
       })()

    }, [])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    const handleSaveDefaultBuildInfo = useCallback( async (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) => {
        e.preventDefault()

        const response = await API.saveDefaultAndroidBuildInfo({
            packageName: input.packageName,
            versionName: input.versionName,
            versionCode: input.versionCode,
            keystorePassword: input.keystorePassword,
            keystoreKey: input.keystoreKey,
            keystoreAlias: input.keystoreAlias
        })

        if(response?.isSuccessful){
            
        }
    
    },[API, input])

    return (
        <>
            <AppText textSize='2.3' color='#101828' fontWeight="700" bottomMargin='1'>Default Build Settings</AppText>
        <ScreenContainer>
            <Form onSubmit={handleSaveDefaultBuildInfo}>
                <CustomContainer topMargin='2'>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Package Name</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="-----" 
                                    name='packageName'
                                    value={input.packageName}
                                    onChange={handleInput}
                                    autoComplete='off'
                                    disabled={inputsDisabled}
                                    required
                                />
                                <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                    {explain(
                                        `This is the package name`, true, '0'
                                    )}
                                </CustomContainer>
                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                            <label>Version Name</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='versionName'
                                    value={input.versionName}
                                    onChange={handleInput}
                                    autoComplete='off'
                                    disabled={inputsDisabled}
                                    required
                                />
                                <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                    {explain(
                                        `This is the version name`, true, '0'
                                    )}
                                </CustomContainer>
                            </div>
                        </FormGroupWithIcon>
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Version Code</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='versionCode'
                                    value={input.versionCode}
                                    onChange={handleInput}
                                    autoComplete='off'
                                    disabled={inputsDisabled}
                                    required
                                />
                                <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                    {explain(
                                        `This is the version code`, true, '0'
                                    )}
                                </CustomContainer>
                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                        <label>Keystore Password</label>
                        <div className="input-with-icon">
                            <input 
                                placeholder="XXXXXXX" 
                                name='keystorePassword'
                                type={showPassword ? 'text' : 'password'}
                                value={input.keystorePassword}
                                onChange={handleInput}
                                autoComplete='off'
                                disabled={inputsDisabled}
                                required
                            />
                            { !showPassword ?
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan 
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(true)}
                                    >
                                        <BsEyeSlash fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                                :
                                <AbsoluteContainer bottom='2' right='3'>
                                    <AppSpan 
                                        color='rgba(0, 0, 0, 0.5)' cursor='pointer'
                                        onClick={() => setShowPassword(false)}
                                    >
                                        <BsEye fontSize='1.7rem' />
                                    </AppSpan>
                                </AbsoluteContainer>
                            }
                            <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                {explain(
                                    `This is the keystore password`, true, '0'
                                )}
                            </CustomContainer>
                        </div>
                    </FormGroupWithIcon>                
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Keystore Key</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='keystoreKey'
                                    value={input.keystoreKey}
                                    onChange={handleInput}
                                    autoComplete='off'
                                    disabled={inputsDisabled}
                                    required
                                />
                                <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                    {explain(
                                        `This is the keystore key`, true, '0'
                                    )}
                                </CustomContainer>
                            </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon>
                            <label>Keystore Alias</label>
                            <div className="input-with-icon">
                                <input 
                                    placeholder="" 
                                    name='keystoreAlias'
                                    value={input.keystoreAlias}
                                    onChange={handleInput}
                                    autoComplete='off'
                                    disabled={inputsDisabled}
                                    required
                                />
                                <CustomContainer width='auto' sizeUnit='' className="input-with-icon-tag" >
                                    {explain(
                                        `This is the keystore alias`, true, '0'
                                    )}
                                </CustomContainer>
                            </div>
                        </FormGroupWithIcon>
                    </FlexRow>
                    <FlexRow gap='2'>
                        <FormGroupWithIcon>
                            <label>Updated At</label>
                                <div className="input-with-icon">
                                    <input 
                                    name='updatedAt'
                                    type='date'
                                    max={helpers.getInputValidDate()}
                                    value={defaultDate}
                                    onChange={handleInput}
                                    autoComplete="off"
                                    disabled={inputsDisabled}
                                />
                                </div>
                        </FormGroupWithIcon>
                        <FormGroupWithIcon />
                    </FlexRow>   
                </CustomContainer>
                { userType === QoreStaffTypes.DEVELOPER &&
                    <FlexRow justifyContent='flex-end' gap='2' bottomMargin='3.5'>
                        <Button
                            width='12' bgColor='#ffffff'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            color='#0D968F' hoverColor='#ffffff'
                            hoverBgColor='#0D968F'
                            borderColor='#0D968F'
                        >
                            Save Settings
                        </Button>
                    </FlexRow>
                }
            </Form>
        </ScreenContainer>
        </>
    )
}

export default utility.routeData('build-configuration', 'Build Configuration', <BuildConfiguration/>)