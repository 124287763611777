import './style.scss'
import { useContext, useState } from 'react'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { AppSpan, AppText, Circle, CustomContainer, FixedContainer, FlexRow, GridContainer, SidePopupContainer } from "src/style"
import { UserImg } from "src/assets/icons"
import { HiOutlineUser } from "react-icons/hi";
import UserIcon from 'src/assets/svg/user-icon'
import RemoteIcon from 'src/assets/svg/remote-icon'
import LogoutIcon from 'src/assets/svg/logout-icon'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import UserPics from 'src/assets/img/rhydhur_pic.jpg'

export const AccountAction: React.FC<any> = ({switchRemote, close}) => {
    const navigate = useNavigate()
    const {info: {userType, role, userData}, setInfoProperty} = useContext(AppInfoContext)
    const {decisionBox} = useContext(VisibilityContext)
    const [isHover, setIsHover] = useState<any>({user: false, remote: false, logout: false})
    const {logout} = useContext(AppInfoContext)

    async function handleViewProfile() {
        await setInfoProperty('selectedUser', userData)
        await setInfoProperty('profileTypeAccess', 'user')
        navigate('/dashboard/user-profile')
        close()
    }

    function handleConfirmLogout () {
        decisionBox.show(
            `Are you sure you want to log out?`,
            handleLogout,
            decisionBox.hide,
            `Yes, Logout`,
            'No'
        )

        close()
    }

    async function handleLogout() {
        await logout()
        navigate('/')
    }
    
    return (
        <>
            <SidePopupContainer zIndex="7" onClick={close} bgColor='transparent' />
            <FixedContainer zIndex="8" top='9' right='10.5' className="acct-action-container">
                <CustomContainer
                    width="24" 
                    minHeight='15' topPadding='1' bottomPadding='1'
                    bgColor="#ffffff" radius="1"
                    shadow="0px 4px 10px 0px #0000000D"
                    className='acct-action-content-container'
                >
                    <FlexRow 
                        justifyContent='flex-start' alignItems='flex-start'
                        leftPadding='1.5' rightPadding='1.5' gap='1'
                        wrap='wrap' bottomMargin='1'
                    >
                        {/* <Circle size="4"
                        >
                            <UserImg style={{width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Circle> */}
                        <Circle 
                            size="4" bgColor='#F9FAFB'
                            style={{overflow: 'hidden'}}
                        >
                            <img src={UserPics} className='ads-img' />
                        </Circle>
                        <CustomContainer width='auto' sizeUnit=''>
                            <AppSpan fontWeight='700' fontFamily='Gilmer-Bold'>{userData.firstName} {userData.lastName}</AppSpan>
                            <AppText color='#667085' topMargin='-0.3'>{utility.getRoleName(userData?.role)}</AppText>
                        </CustomContainer>
                    </FlexRow>
                    <GridContainer 
                        minHeight='4' leftPadding='1.5' rightPadding='1.5' 
                        borderColor='#EAECF0' bgColor={isHover.user ? '#E7F6F5' : 'transparent'}
                        onMouseEnter={() => setIsHover({user: true})}
                        onMouseLeave={() => setIsHover({user: false})}
                        onClick={handleViewProfile}
                    >
                        <FlexRow gap='1' justifyContent='flex-start'>
                            <UserIcon className='icon-component' isHover={isHover.user} />
                            <AppText 
                                cursor='pointer' color={isHover.user ? '#0A756F' : '#344054'}
                            >
                                View Profile
                            </AppText>
                        </FlexRow>
                    </GridContainer>
                    { role === 'SA' &&
                        <GridContainer 
                            minHeight='4' leftPadding='1.5' rightPadding='1.5' 
                            borderColor='#EAECF0' bgColor={isHover.remote ? '#E7F6F5' : 'transparent'}
                            style={{borderTop: 'none'}}
                            onMouseEnter={() => setIsHover({remote: true})}
                            onMouseLeave={() => setIsHover({remote: false})}
                            onClick={switchRemote}
                        >
                            <FlexRow gap='1' justifyContent='flex-start'>
                                <RemoteIcon className='icon-component' isHover={isHover.remote}/>
                                <AppText 
                                    cursor='pointer' color={isHover.user ? '#0A756F' : '#344054'}
                                >
                                    Switch Institution View
                                </AppText>
                            </FlexRow>
                        </GridContainer>
                    }
                    <GridContainer 
                        minHeight='4' leftPadding='1.5' rightPadding='1.5' 
                        bgColor={isHover.logout ? '#E7F6F5' : 'transparent'}
                        onMouseEnter={() => setIsHover({logout: true})}
                        onMouseLeave={() => setIsHover({logout: false})}
                        onClick={handleConfirmLogout}
                    >
                        <FlexRow gap='1' justifyContent='flex-start'>
                            <LogoutIcon className='icon-component' isHover={isHover.logout}/>
                            <AppText 
                                cursor='pointer' color={isHover.user ? '#0A756F' : '#344054'}
                            >
                                Log out
                            </AppText>
                        </FlexRow>
                    </GridContainer>
                </CustomContainer>
            </FixedContainer>
        </>
    )
}