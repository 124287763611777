import { useContext } from "react"
import ApiContext from "src/provider/API/call-service"
import { RiCloseFill } from "react-icons/ri"
import { AbsoluteContainer, AppLink, AppSpan, AppText, AuthOtpLabel, Button, FlexColumn, FlexRow, Form, FormGroup, GridContainer, SidePopupContainer } from "src/style"
import {ReactComponent as PasswordIcon} from 'src/assets/img/secure-password-icon.svg'
import { handleTypeNumberControl } from "src/utils/utility"
import ResendIcon from 'src/assets/svg/resend-icon'


export const OTPVerification: React.FC<any> = ({onInput, formData, handleNext, close}) => {
    const {API} = useContext(ApiContext)

    function handleProceed (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        handleNext()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    One Time Password
                </AppText>
                <GridContainer topMargin="2">
                    <PasswordIcon />
                </GridContainer>
                <AppText color="#000000" topMargin="1">
                    Please enter the One Time Password (OTP) sent to your Email
                    {/* <AppSpan color='#0D968F'>{formData.email}</AppSpan> */}
                </AppText>
                <Form onSubmit={handleProceed}>
                    <GridContainer>
                        <FlexRow 
                            width="auto" sizeUnit="" 
                            topMargin='2' gap='1.4'
                            justifyContent="center"
                        >
                            <input 
                                className='display-hidden' id='otp' 
                                name="otp" value={formData?.otp} 
                                onChange={onInput} type='number'
                                onKeyDown={handleTypeNumberControl}
                                onKeyUp={handleTypeNumberControl}
                                autoFocus={true} autoComplete="off"
                            />
                            <AuthOtpLabel className={`${formData?.otp.length === 0 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[0] ? '*' : ''}</AuthOtpLabel>
                            <AuthOtpLabel className={`${formData?.otp.length === 1 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[1] ? '*' : ''}</AuthOtpLabel>
                            <AuthOtpLabel className={`${formData?.otp.length === 2 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[2] ? '*' : ''}</AuthOtpLabel>
                            <AuthOtpLabel className={`${formData?.otp.length === 3 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[3] ? '*' : ''}</AuthOtpLabel>
                            <AuthOtpLabel className={`${formData?.otp.length === 4 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[4] ? '*' : ''}</AuthOtpLabel>
                            <AuthOtpLabel className={`${formData?.otp.length >= 5 ? 'active-otp-box' : ''}`} htmlFor='otp' >{formData?.otp[5] ? '*' : ''}</AuthOtpLabel>
                        </FlexRow>
                    </GridContainer>
                    <FlexRow 
                        gap='1.5' 
                        bgColor='#E7F6F5' minHeight='6.2' radius='0.8'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                        padding='2' topPadding='1' bottomPadding='1'
                        topMargin='2.5' bottomMargin="3"
                        justifyContent="flex-start"
                    >
                        <ResendIcon className='icon-component' />
                        <AppText textSize='1.2' color='#0A756F'>
                            Did not receive OTP.
                            <AppLink 
                                textSize='1.2' color='#0A756F' 
                                fontWeight='700' hoverColor='#000000'
                                leftMargin="1"
                                onClick={() => API.sendForgotPasswordOtp(formData.ibInstitutionCode, formData.email)}
                            > 
                                Tap here to resend 
                            </AppLink> 
                        </AppText>
                    </FlexRow>
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='8.8'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Proceed
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}