import './style.scss'
import { useState, useContext, useEffect } from 'react';
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service';
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { 
    AbsoluteContainer,
    AppSpan,
    AppText, Button, CustomContainer, FlexRow, ScreenContainer 
} from 'src/style';
//import NotificationImg from 'src/assets/img/notification.svg'
import { AddNewNotification } from './popups';
//import { IoTrashOutline } from 'react-icons/io5';

export const Notification = () => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [notificationInfo, setNotificationInfo] = useState<any>({})
    const [notifications, setNotifications] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1)
    const [showAddNewNotification, setShowAddNewNotification] = useState(false)

    useEffect(() => {
        handleFetchNotifications()
    }, [])

    async function handleFetchNotifications(pageNumber = 1) {
        const response = await API.getNotifications(pageNumber)
        if (response) {
            setNotificationInfo(response)
            setNotifications(response.data)
        }
    }

    async function handlePagination(type: string) {
        let newPageNo = currentPageNo
        if (type === 'next') newPageNo += 1
        else newPageNo -= 1

        await handleFetchNotifications(newPageNo)
        setCurrentPageNo(newPageNo)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer>
                    <FlexRow 
                        justifyContent='space-between' alignItems='flex-start' 
                        className='notification-title-wrapper'
                    >
                        <AppText textSize='1.9' color='#101828' fontWeight="700">
                            Push Notification Management
                        </AppText>
                        <Button
                            width='15' leftMargin='2'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                            onClick={() => setShowAddNewNotification(true)}
                        >
                            New Notification
                        </Button>
                    </FlexRow>
                    <CustomContainer topMargin='1' bottomMargin='2'>
                        {
                            notifications?.map((item: any, index: number) => {
                                return (
                                    // <CustomContainer key={index} topMargin='2'>
                                    //     <CustomContainer
                                    //         minHeight='12' padding='2' radius='0.8'
                                    //         borderColor='#D0D5DD'
                                    //     >
                                    //         <FlexRow 
                                    //             justifyContent='flex-start' width='90' sizeUnit='%'
                                    //             alignItems='flex-start'
                                    //         >
                                    //             {/* <CustomContainer maxHeight='8' rightMargin='2' className='notification-img-wrapper'>
                                    //                 <img src={NotificationImg} className='notification-img' />
                                    //             </CustomContainer> */}
                                    //             <img src={NotificationImg} className='notification-img' />
                                    //             <AppText color='#101828' textSize='1.6' fontWeight='300'>
                                    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                    //                 incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    //             </AppText>
                                    //         </FlexRow>
                                    //         <AbsoluteContainer top='1.8' right='2'>
                                    //             <AppSpan 
                                    //                 textSize="2" color='#111111' hoverColor="#F80000" 
                                    //                 onClick={handleSubmit}
                                    //             >
                                    //                 <IoTrashOutline />
                                    //             </AppSpan>
                                    //         </AbsoluteContainer>
                                    //     </CustomContainer>
                                    // </CustomContainer>
                                    <CustomContainer 
                                        minHeight='12' padding='2' radius='0.8'
                                        borderColor='#D0D5DD' topMargin='2'
                                        key={index}
                                    >
                                        <AppSpan textSize='1.8' color='#101828' fontWeight='700'>{item.title}</AppSpan>
                                        <AppText color='#101828' textSize='1.6' fontWeight='300' topMargin='2'>
                                            {item.message}
                                        </AppText>
                                    </CustomContainer>
                                )
                            })
                        }
                        <FlexRow justifyContent='flex-end' gap="1.5" topMargin="4.5">
                            { currentPageNo > 1 &&
                                <Button 
                                    width='8.7' height='4' bgColor="#ffffff"
                                    borderColor='#D0D5DD' hoverBgColor='#ffffff' 
                                    hoverColor='#0D968F' titleSize='1.4' color="#344054"
                                    onClick={() => handlePagination('prev')}
                                >
                                    Previous
                                </Button>
                            }
                            { (!!notifications?.length && currentPageNo != notificationInfo?.pageCount) &&
                                <Button 
                                    width='8.7' height='4' 
                                    borderColor='#0D968F' hoverBgColor='#ffffff' 
                                    hoverColor='#0D968F' titleSize='1.4'
                                    onClick={() => handlePagination('next')}
                                >
                                    Next
                                </Button>
                            }
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { showAddNewNotification &&
                <AddNewNotification 
                    close={() => setShowAddNewNotification(false)}
                    getNotifications={() => handleFetchNotifications(1)}
                />
            }
        </>
    )
}

export default utility.routeData('notification', 'Notification', <Notification/>)