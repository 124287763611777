import './style.scss'
import { useState, useEffect } from "react"
import { GoCheck } from "react-icons/go"
import { AppText, CustomContainer, FlexRow } from "src/style"

export const CheckboxSelection: React.FC<any> = ({
    name, output, value, width, bottomMargin, 
    fontWeight, textSize, customClass, reset,
    disabled
}) => {
    const [input, setInput] = useState(value || false)

    useEffect(() => {
        setInput(false)
    }, [reset, value])

    useEffect(() => {
        setInput(value)
    }, [value])

    function handleSelection () {
        if (!disabled) {
            setInput(!input)
            output && output(!input)
        }
    }

    return (
        <CustomContainer
            width={width || '100'} sizeUnit="%" minHeight="5.2" bottomMargin={bottomMargin || 1} 
            className={`checkbox-selection-container ${customClass}`}
            borderColor={input ? '#0D968F' : '#EAECF0'}
            bgColor={disabled ? 'rgba(178, 190, 181, 0.1)' : input ? '#E7F6F5' : 'transparent'}
            onClick={handleSelection}
        >
            <FlexRow justifyContent="flex-start">
                <div className={`checkbox-check-wrap ${input && 'checkbox-check-clicked'}`}>
                    {input ? <GoCheck className='checkbox-check-icon' /> : null}
                </div>
                <AppText 
                    textSize={textSize || "1.4"} fontWeight={fontWeight || "600"} cursor="pointer"
                    color={disabled ? 'rgba(178, 190, 181, 0.7)' : input ? '#085B56' : '#344054'}
                >
                    {name}
                </AppText>
            </FlexRow>
        </CustomContainer>
    )
}