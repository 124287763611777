import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ApiContext from "src/provider/API/call-service";
import { AppText, Button, FlexColumn, FlexRow, Form, FormGroup, GridContainer, SidePopupContainer } from "src/style"
import { ReactComponent as DecisionBoxIcon } from 'src/assets/img/decision-box-icon.svg'
import utility from "src/utils/utility";


export const Approve_DeclineUpgradeRequest: React.FC<any> = ({type, requestData, close}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [comment, setComment] = useState('')

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const payload = {
            reason: comment, 
            accountNumber: requestData.accountNumber, 
            CustomerID: requestData.customerID
        }

        const response = type === 'approve' ? 
            await API.upgradeAccount(payload)
            : await API.RejectAccountUpgradeRequest(payload)

        if (response) navigate(-1)
        close()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                
                <GridContainer bottomMargin="1">
                    <DecisionBoxIcon className="icon-component" />
                    <AppText textSize="1.6" color="#101828" topMargin="1.5">Are you sure you want to {type}?</AppText>
                </GridContainer>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <label>Comment</label>
                        <textarea 
                            placeholder="Add comment" 
                            name='comment'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                        />
                    </FormGroup>
                    
                    <FlexRow 
                        justifyContent='flex-end'
                        gap='1' topMargin='2' 
                    >
                        <Button
                            width='5.1' bgColor='#ffffff'
                            fontWeight='600'
                            height='4' color='#344054'
                            borderColor='#D0D5DD'
                            hoverBorderColor='#0D968F'
                            onClick={close}
                        >
                            No
                        </Button>
                        <Button
                            width='12' 
                            bgColor={type === 'approve' ? "#0D968F" : "#D92D20"}
                            fontWeight='600' height='4'
                            hoverColor={type === 'approve' ? "#0D968F" : "#D92D20"}
                            borderColor={type === 'approve' ? "#0D968F" : "#D92D20"}
                            hoverBgColor='#fff'
                        >
                            Yes, {utility.toTitleCase(type)}
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}