import React, { useContext } from 'react'
import { DecisionBox, Loader, Notifier } from '../index'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'

export const PopUps: React.FC<any> = () => {
    const { visibility: {isLoading, decision} } = useContext(VisibilityContext)

    return (
        <>
            { isLoading ? <Loader /> : null }
            <Notifier />
            { decision.status &&
                <DecisionBox 
                    yesBtnText={decision.yesBtnText}
                    noBtnTxt={decision.cancelBtnText}
                    message={decision.message}
                    height='22.5'
                    yesMethod={decision.yesMethod}
                    close={decision.noMethod} 
                />
            }
        </>
    )
}