import { useState, useContext } from "react";
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from "src/provider/API/call-service";
import { AbsoluteContainer, AppText, Button, FlexColumn, FlexRow, Form, FormGroup, SidePopupContainer } from "src/style"


export const AddNewSlide: React.FC<any> = ({getSlides, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({title: '', body: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        const charLength = e.target.name === 'title' ? 25 : 100
        if (e.target.value.length <= charLength) setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const response = await API.addNewOnboardingSlide(input)
        if (response) {
            if (getSlides) await getSlides()
        }    
        close()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    New Slide
                </AppText>
                <Form onSubmit={handleSubmit}>
                    <FormGroup topMargin='2'>
                        <label>Slider Title</label>
                        <input 
                            placeholder="Enter Slide Title (max characters length is 25)" 
                            name='title'
                            value={input.title}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Slide Body Text</label>
                        <textarea 
                            placeholder="Enter Slide Text (max characters length is 100)" 
                            name='body'
                            value={input.body}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>
                    
                    <FlexRow justifyContent='flex-end' topMargin='2'>
                        <Button
                            width='10.9'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Save Slide
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}