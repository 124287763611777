import { useContext, useState } from "react"
import { NavLink } from "react-router-dom"
import { 
    SideBarLinkWrapper, 
    SideBarLabel, 
} from './style'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import './style.scss'

export const SidebarMenu: React.FC<any> = ({item}) => {
    const {showSideBar} = useContext(VisibilityContext) 
    const [subNav, setSubNav] = useState(false)

    const handleSideBarShow = async () => {
        if(window.matchMedia('(max-width: 770px)').matches) {
            await showSideBar(false)
        }

    }

    return (
        <>
            { item?.path ?
                <NavLink
                    to={item?.path}
                    className={({ isActive }) => `default-menu ${isActive && 'active-menu'}`}
                    onClick={handleSideBarShow}
        
                >
                    <SideBarLinkWrapper className="nav-option" >
                        <SideBarLabel>{item.title}</SideBarLabel>
                    </SideBarLinkWrapper>
                </NavLink>
                :
                <div 
                    className="default-menu"
                    onClick={() => setSubNav(!subNav)}
                >
                    <SideBarLinkWrapper className="nav-option" >
                        {item.icon}
                        <SideBarLabel>{item.title}</SideBarLabel>
                    </SideBarLinkWrapper>
                    { 
                        item.subNav && subNav 
                        ? item.iconOpened 
                        : item.subNav 
                        ? item.iconClosed 
                        : null
                    }
                </div>
            }
            {
                subNav && item.subNav.map((subNavItem: any, subNavIndex: number) => {
                    return (
                        <NavLink
                            key={subNavIndex} end
                            to={subNavItem?.path}
                            className={({ isActive }) => `default-menu ${isActive && 'active-menu'}`}
                            onClick={handleSideBarShow}
                
                        >
                            <SideBarLinkWrapper style={{marginLeft: '1rem'}} >
                                <SideBarLabel>{subNavItem.title}</SideBarLabel>
                            </SideBarLinkWrapper>
                        </NavLink>
                    )
                })
            }
        </>
    )
}