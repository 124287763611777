import './style.scss'

export const IPhone: React.FC<any> = ({containerClass, contentClass, notchClass, children}) => {
    return (
        <div className={containerClass || 'phone-main-wrapper'}>
            <span className="silent-toggle"></span>
            <span className="volume-up"></span>
            <span className="volume-down"></span>
            <span className="power-button"></span>
            <div className={contentClass || "content-container"}>
                <span className={notchClass || "top-notch"}></span>
                <div className="notification-container">
                    <span className="notif-time">9:40</span>
                    <div className="notif-icon-wrap">
                        <i className="fas fa-signal notif-icon"></i>
                        <i className="fas fa-wifi notif-icon"></i>
                        <i className="fas fa-battery-full notif-icon"></i>
                    </div>
                </div>
                <div className="phone-content-wrapper">
                    {children}
                </div>
            </div>
        </div>
    )
}