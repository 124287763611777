import './style.scss'
import { useContext, useEffect, useState } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { AbsoluteContainer, AppLink, AppSpan, AppText, Button, Circle, CustomContainer, FlexColumn, FlexRow, Form } from 'src/style'
import { BiUser } from 'react-icons/bi'
import { GoStar } from 'react-icons/go'
import { Logo } from 'src/assets/icons'
import access from 'src/utils/localAccess'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { ForgotPassword } from 'src/popup'


const SignIn: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info} = useContext(AppInfoContext)
    const [isAuth, setIsAuth] = useState<boolean>(!!info.adminAuthToken)
    const [input, setInput] = useState({ibInstitutionCode: '', userID: '', password: ''})
    const [showPassword, setShowPassword] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false)

    useEffect(() => {
        initializer()
    }, [])

    async function initializer () {
        access.setNavigationAccess(navigate)
        let authToken = await localStorage.getItem('adminAuthToken')
        
        if (!authToken) setIsAuth(!!authToken)
        else navigate('dashboard')
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) {
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleLogin (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const payload = {...input}
        if (!payload.ibInstitutionCode) payload.ibInstitutionCode = '0'

        const response = await API.login(payload)
        if (response) navigate('/dashboard/app-design')
    }
    
    return (
        <>
            { !isAuth &&
                <CustomContainer width='100' sizeUnit='%' height='100' hUnit='%'>
                    <FlexRow alignItems='stretch' justifyContent='flex-start' height='100' hUnit='%'>
                        <FlexColumn 
                            width='50' sizeUnit='%' minHeight='100' mnHUnit='%'
                            padding='2' className='login-about-section'
                        >
                            <FlexColumn width='90' sizeUnit='%'>
                                <Logo style={{marginBottom: '6rem'}}/>
                                <AppText color='#101828' textSize='2.5' fontWeight='600' align='center'>
                                    This platform has helped us decide between different designs and 
                                    iterations moving into production faster
                                </AppText>
                                <Circle size='5.6' bgColor='#ffffff' topMargin='4' bottomMargin='2'>
                                    <BiUser style={{color: '#475467', fontSize: '3.5rem'}} />
                                </Circle>
                                <CustomContainer>
                                    <AppText color='#101828' textSize='1.6' fontWeight='600' align='center'>Kelly Williams</AppText>
                                    <AppText color='#667085' fontWeight='600' align='center' topPadding='0.5'>Project Manager, Layers</AppText>
                                </CustomContainer>
                                <FlexRow width='auto' sizeUnit='' gap='0.3' topMargin='4'>
                                    {
                                        Array.from(Array(5).keys()).map((item: number, index: number) => {
                                            return (
                                                <GoStar style={{color: '#FEC84B', fontSize: '2.3rem'}} key={index}/>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn 
                            width='50' sizeUnit='%' minHeight='100' mnHUnit='%'
                            bgColor='#ffffff' padding='2'
                            className='login-form-section'
                        >
                            <CustomContainer 
                                width='40' leftMargin='auto' rightMargin='auto' sizeUnit=''
                                className='login-form-container'
                            >
                                <Logo className='login-form-logo'/>
                                <AppText 
                                    color='#101828' textSize='3' fontWeight='600' bottomPadding='0.7'
                                    className='login-title'
                                >
                                    Log in to your account
                                </AppText>
                                <AppSpan 
                                    color='#667085' textSize='1.6'
                                    className='login-instruction'
                                >
                                    Use your exisiting BankOne login credentials
                                </AppSpan>
                                <Form onSubmit={handleLogin}>
                                    <CustomContainer topMargin='3' bottomMargin='2.5'>
                                        <input 
                                            className='login-icode' 
                                            type='number' 
                                            placeholder='Enter Institution code'
                                            value={input.ibInstitutionCode}
                                            name='ibInstitutionCode' 
                                            onChange={handleInput}
                                            autoComplete="off"
                                        />
                                        <input 
                                            className='login-email' 
                                            placeholder='Enter your UserId' 
                                            value={input.userID}
                                            name='userID' 
                                            onChange={handleInput}
                                            autoComplete="off"
                                            required
                                        />
                                        <CustomContainer>
                                            <input 
                                                className='login-password' 
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder='**********'
                                                value={input.password}
                                                name='password' 
                                                onChange={handleInput}
                                                required
                                            />
                                            <AbsoluteContainer bottom='1.2' right='2'>
                                                { !showPassword ?
                                                    <AppSpan 
                                                        color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                                        onClick={() => setShowPassword(true)}
                                                    >
                                                        <BsEyeSlash/>
                                                    </AppSpan>
                                                    :
                                                    <AppSpan 
                                                        color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                                        onClick={() => setShowPassword(false)}
                                                    >
                                                        <BsEye/>
                                                    </AppSpan>
                                                }
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    </CustomContainer>
                                    <Button
                                        width='100' sizeUnit='%'
                                        titleSize='1.6' fontWeight='600'
                                        height='4.4' radius='0.8'
                                        bottomMargin='3.5' hoverColor='#0D968F'
                                        hoverBgColor='#fff' borderColor='#0D968F'
                                    >
                                        Sign in
                                    </Button>
                                    <AppText align='center'>
                                        <AppLink 
                                            decoration='none' color='#1E1E1E' 
                                            fontWeight='600' hoverColor='#0D968F'
                                            onClick={() => setShowForgotPassword(true)}
                                        >
                                            Forgot password
                                        </AppLink>
                                    </AppText>
                                </Form>
                            </CustomContainer>
                        </FlexColumn>
                    </FlexRow>
                </CustomContainer>
            }
            { showForgotPassword &&
                <ForgotPassword 
                    close={() => setShowForgotPassword(false)}
                />
            }
        </>
    )
}

export default utility.routeData('/', 'SignIn', <SignIn/>)