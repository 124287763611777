import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import utility from 'src/utils/utility'
import { AppText, CustomContainer, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { auditReportData, auditReportColumn } from 'src/utils/tableLayout';
import { UserTypes } from 'src/model';


const AuditReport = () => {
    const navigate = useNavigate()
    const {API, downloadReport} = useContext(ApiContext)
    const {info: {userType, role}, setInfoProperty} = useContext(AppInfoContext)
    const [reportData, setReportData] = useState<any>(null)
    const [report, setReport] = useState<any>(null)
    const [view, setView] = useState<string>('')
    const [user, setUser] = useState({role: '', type: ''})
    const [resetPagination, setResetPagination] = useState<boolean>(false)

    const filterDataRef = useRef({})

    useEffect(() => {
        if (userType) setUser({role: role, type: userType})
    }, [userType, role])
    
    useEffect(() => {
        filterDataRef.current = {}
        if (userType) handleFetchReport()
    }, [userType])

    async function handleFetchReport (pageNumber = 1, pageSize = 10, isDownload = false) {
        const response = await API.getAuditReport(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload}, userType === UserTypes.SUPER_ADMIN)
        if (response) {
            if (isDownload) {
                return downloadReport(response, 'AUDIT_REPORT')
            }
            setReportData(response)
            setReport(helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize}))
        }
    }

    async function handleSelectedAudit (data: any) {
        await setInfoProperty('selectedAudit', data)
        navigate('/dashboard/audit-report/details')
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchReport()
        setResetPagination(!resetPagination)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' fontWeight="600" bottomMargin='1'>
                        Audit Report
                    </AppText>
                    <ServerSideTable
                        tableData={report}
                        tableColumns={auditReportColumn(handleSelectedAudit)} 
                        dataFetchMethod={handleFetchReport}
                        totalRows={reportData?.total}
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setView('report')}
                        canClear={handleClear}
                        downloadAction={() => handleFetchReport(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { view === 'report' &&
                <ReportFilter
                    type='audit-report'
                    onApplyFilter={handleFetchReport}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setView('')} 
                />
            }
        </>
    )
}

export default utility.routeData('audit-report', 'Audit Report', <AuditReport/>)