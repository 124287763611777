import AppDesign from "./appDesign";
import AppDetails from "./appDetails";
import Configuration from "./configuration";
import FeatureControl from "./featureControl";
import AppGeneration from "./appGeneration";
import IBankingFeed from "./ibankingFeed";
import Notification from "./notification";
import TransferReport from "./transferReport";
import BillPaymentReport from "./billPaymentReport";
import AirtimeReport from "./airtimeReport";
import MBankingFeed from "./mbankingFeed";
import OnboardingSlides from "./onboardingSlides";
import CustomerProfileReport from "./customerProfileReport";
import CustomerLoginReport from "./customerLoginReport";
import FailedTxReport from "./failedTxReport";
import TxLimitReport from "./txLimitReport";
import FixedDepositReport from "./fixedDepositReport";
import CardlessWithdrawalReport from "./cardlessWithdrawalReport";
import LoansReport from "./loansReport";
import TargetSavingsReport from "./targetSavingsReport";
import Institutions from "./institutions";
import AuditReport from "./auditReport";
import LoginAuditReport from "./loginAuditReport";
import Users from "./users";
import Profile from "./userProfile";
import InstitutionDetails from "./institutionDetails";
import AuditReportDetails from "./auditReportDetails";
import AccountUpgradeRequests from "./accountUpgradeRequests";
import AccountUpgradeRequestDetails from "./accountUpgradeDetails";
import AppGenerationReport from "./appGenerationReport";
import BuildConfiguration from "./buildConfiguration";
import Faq from "./faqs";

export default [
  AppDesign,
  AppDetails,
  Configuration,
  FeatureControl,
  AppGeneration,
  IBankingFeed,
  Notification,
  TransferReport,
  BillPaymentReport,
  AirtimeReport,
  MBankingFeed,
  OnboardingSlides,
  CustomerProfileReport,
  CustomerLoginReport,
  FailedTxReport,
  TxLimitReport,
  FixedDepositReport,
  CardlessWithdrawalReport,
  LoansReport,
  TargetSavingsReport,
  Institutions,
  AuditReport,
  LoginAuditReport,
  Users,
  Profile,
  InstitutionDetails,
  AuditReportDetails,
  AccountUpgradeRequests,
  AccountUpgradeRequestDetails,
  AppGenerationReport,
  Faq,
  BuildConfiguration,
];
