import { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from 'src/provider/API/call-service';
import utility from 'src/utils/utility'
import { CustomContainer, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { customerLoginReportData, customerLoginReportColumn } from 'src/utils/tableLayout';


const CustomerLoginReport = () => {
    const {API, downloadReport} = useContext(ApiContext)
    const [reportData, setReportData] = useState<any>(null)
    const [report, setReport] = useState<any>(null)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [resetPagination, setResetPagination] = useState<boolean>(false)

    const filterDataRef = useRef({})
    
    useEffect(() => {
        filterDataRef.current = {}
        handleFetchReport()
    }, [])

    async function handleFetchReport (pageNumber = 1, pageSize = 10, isDownload = false) {
        const response = await API.getLoginReport(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload})
        if (response) {
            if (isDownload) {
                return downloadReport(response, 'CUSTOMER_LOGIN_REPORT')
            }
            setReportData(response)
            setReport(helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize}))
        }
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchReport()
        setResetPagination(!resetPagination)
    }


    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3'>
                    <ServerSideTable
                        tableData={report}
                        tableColumns={customerLoginReportColumn} 
                        dataFetchMethod={handleFetchReport}
                        totalRows={reportData?.total}
                        tableTitle='Customer Login Report'
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setShowFilter(true)}
                        canClear={handleClear}
                        downloadAction={() => handleFetchReport(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { showFilter &&
                <ReportFilter
                    type='customer-login'
                    onApplyFilter={handleFetchReport}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setShowFilter(false)} 
                />
            }
        </>
    )
}

export default utility.routeData('customer-login-report', 'Customer Login Report', <CustomerLoginReport/>)