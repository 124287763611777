function SvgComponent(props: any) {

    return (
        <svg
            width={1066}
            height={987}
            viewBox="0 0 1066 987"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask
                id="a"
                style={{
                maskType: "alpha"
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={1066}
                height={987}
            >
                <ellipse
                cx={533}
                cy={493.5}
                rx={533}
                ry={493.5}
                fill="url(#paint0_radial_4143_61581)"
                fillOpacity={0.6}
                />
            </mask>
            <g mask="url(#a)" stroke="#023AAE">
                <path d="M566.522 493.501c0 17.531-15.362 31.808-34.394 31.808-19.032 0-34.394-14.277-34.394-31.808s15.362-31.808 34.394-31.808c19.032 0 34.394 14.277 34.394 31.808z" />
                <path d="M618.861 493.5c0 44.296-38.796 80.269-86.734 80.269-47.939 0-86.734-35.973-86.734-80.269 0-44.296 38.795-80.27 86.734-80.27 47.938 0 86.734 35.974 86.734 80.27z" />
                <path d="M671.204 493.5c0 71.061-62.229 128.731-139.075 128.731-76.846 0-139.074-57.67-139.074-128.731 0-71.06 62.228-128.73 139.074-128.73s139.075 57.67 139.075 128.73z" />
                <path d="M723.545 493.499c0 97.825-85.663 177.192-191.415 177.192-105.753 0-191.415-79.367-191.415-177.192s85.662-177.192 191.415-177.192c105.752 0 191.415 79.367 191.415 177.192z" />
                <path d="M775.884 493.502c0 124.589-109.096 225.653-243.756 225.653-134.659 0-243.755-101.064-243.755-225.653 0-124.59 109.096-225.654 243.755-225.654 134.66 0 243.756 101.064 243.756 225.654z" />
                <path d="M828.223 493.5c0 151.354-132.53 274.116-296.096 274.116S236.031 644.854 236.031 493.5c0-151.354 132.53-274.115 296.096-274.115S828.223 342.146 828.223 493.5z" />
                <path d="M880.564 493.501c0 178.119-155.963 322.577-348.436 322.577-192.473 0-348.437-144.458-348.437-322.577s155.964-322.577 348.437-322.577 348.436 144.458 348.436 322.577z" />
                <path d="M932.903 493.499c0 204.884-179.397 371.039-400.777 371.039S131.35 698.383 131.35 493.499c0-204.883 179.396-371.038 400.776-371.038 221.38 0 400.777 166.155 400.777 371.038z" />
                <path d="M985.244 493.5c0 231.648-202.831 419.5-453.117 419.5C281.84 913 79.01 725.148 79.01 493.5S281.84 74 532.127 74c250.286 0 453.117 187.852 453.117 419.5z" />
                <path d="M1020.14 493.499c0 249.491-218.456 451.808-488.014 451.808S44.116 742.99 44.116 493.499c0-249.491 218.452-451.808 488.01-451.808S1020.14 244.008 1020.14 493.5z" />
                <path d="M1065.5 493.5c0 272.241-238.371 493-532.5 493S.5 765.741.5 493.5 238.871.5 533 .5s532.5 220.759 532.5 493z" />
            </g>
            <defs>
                <radialGradient
                id="paint0_radial_4143_61581"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(0 493.5 -533 0 533 493.5)"
                >
                <stop stopColor="#fff" stopOpacity={0.3} />
                <stop offset={1} stopColor="#fff" stopOpacity={0.02} />
                </radialGradient>
            </defs>
        </svg>
    )
}
  
  export default SvgComponent