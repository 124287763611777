import { useState, useContext } from "react";
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from "src/provider/API/call-service";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import { AbsoluteContainer, AppSpan, AppText, Button, FlexColumn, FlexRow, Form, FormGroup, GridContainer, SidePopupContainer } from "src/style"
import {ReactComponent as PasswordIcon} from 'src/assets/img/secure-password-icon.svg'
import helpers from "src/utils/helpers";
import { BsEye, BsEyeSlash } from "react-icons/bs";


export const ChangePassword: React.FC<any> = ({isNewUserCheck, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info, setInfoProperty} = useContext(AppInfoContext)
    const [input, setInput] = useState({
        oldPassword: '', newPassword: '', confirmPassword: ''
    })
    const [showPassword, setShowPassword] = useState({oldPassword: false, newPassword: false, confirmPassword: false})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (input.confirmPassword != input.newPassword) return notifier.show('Password Confirmation did not match', 'Validation Response')
        if (!helpers.validatePassword(input.newPassword)) return notifier.show('Please ensure your password has a special character, a lowercase letter, a number and an uppercase letter', 'Validation Response')

        const response = await API.changeAdminUserPassword(input)  
        if (response) {
            setInfoProperty('userData', {...info.userData, isPasswordChanged: true})
            close()
        }
        
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={isNewUserCheck ? null : close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                { !isNewUserCheck &&
                    <AbsoluteContainer top='1.5' right='1.5'>
                        <Button 
                            titleSize="2" fontWeight="500"
                            width='3.4' shadow='none'
                            height='3.4'
                            radius='0.4'
                            bgColor='#ffffff'
                            hoverBgColor='#FEF3F2'
                            borderColor='none'
                            color='#000000'
                            hoverColor='#B42318'
                            onClick={close}
                        >
                            <RiCloseFill style={{fontSize: '2.3rem'}} />
                        </Button> 
                    </AbsoluteContainer>
                }
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Change Password
                </AppText>
                <GridContainer topMargin="2">
                    <PasswordIcon />
                </GridContainer>
                <Form onSubmit={handleSubmit} topMargin='1'>
                    <FormGroup>
                        <label>Old Password</label>
                        <input 
                            placeholder="XXXXXXX" 
                            name='oldPassword'
                            type={showPassword.oldPassword ? 'text' : 'password'}
                            value={input.oldPassword}
                            onChange={handleInput}
                            required
                        />
                        { !showPassword.oldPassword ?
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, oldPassword: true})}
                                >
                                    <BsEyeSlash/>
                                </AppSpan>
                            </AbsoluteContainer>
                            :
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, oldPassword: false})}
                                >
                                    <BsEye/>
                                </AppSpan>
                            </AbsoluteContainer>
                        }
                    </FormGroup>  
                    <FormGroup>
                        <label>New password</label>
                        <input 
                            placeholder="XXXXXXX" 
                            name='newPassword'
                            type={showPassword.newPassword ? 'text' : 'password'}
                            value={input.newPassword}
                            onChange={handleInput}
                            required
                        />
                        { !showPassword.newPassword ?
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, newPassword: true})}
                                >
                                    <BsEyeSlash/>
                                </AppSpan>
                            </AbsoluteContainer>
                            :
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, newPassword: false})}
                                >
                                    <BsEye/>
                                </AppSpan>
                            </AbsoluteContainer>
                        }
                    </FormGroup> 
                    <FormGroup>
                        <label>Confirm password</label>
                        <input 
                            placeholder="XXXXXXX" 
                            name='confirmPassword'
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            value={input.confirmPassword}
                            onChange={handleInput}
                            required
                        />
                        { !showPassword.confirmPassword ?
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, confirmPassword: true})}
                                >
                                    <BsEyeSlash/>
                                </AppSpan>
                            </AbsoluteContainer>
                            :
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, confirmPassword: false})}
                                >
                                    <BsEye/>
                                </AppSpan>
                            </AbsoluteContainer>
                        }
                    </FormGroup> 
                    
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='12.9'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Save Changes
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}