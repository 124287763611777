function SvgComponent(props: any) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.333 6v5.333m3-5.333v5.333M9.667 6v5.333m3-5.333v5.333M2 12.4v.533c0 .374 0 .56.073.703a.666.666 0 00.291.291c.143.073.33.073.703.073h9.866c.374 0 .56 0 .703-.073a.666.666 0 00.291-.291c.073-.143.073-.33.073-.703V12.4c0-.373 0-.56-.073-.703a.667.667 0 00-.291-.291c-.143-.073-.33-.073-.703-.073H3.067c-.374 0-.56 0-.703.073a.667.667 0 00-.291.291C2 11.84 2 12.027 2 12.4zM7.769 2.05L2.835 3.148c-.298.066-.447.099-.558.179a.667.667 0 00-.223.278C2 3.73 2 3.884 2 4.189v.744c0 .374 0 .56.073.703a.667.667 0 00.291.291c.143.073.33.073.703.073h9.866c.374 0 .56 0 .703-.073a.667.667 0 00.291-.291c.073-.143.073-.33.073-.703V4.19c0-.305 0-.458-.054-.584a.667.667 0 00-.223-.278c-.111-.08-.26-.113-.558-.18L8.23 2.052a1.389 1.389 0 00-.173-.032.666.666 0 00-.116 0 1.389 1.389 0 00-.173.032z"
                stroke={props.isHover ? '#0A756F' : '#344054'}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
        )
  }
  
  export default SvgComponent