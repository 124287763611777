import { AppText, AppSpan, GridContainer } from "src/style";
import './style.scss';
import ApkDownloadIcon from 'src/assets/img/apk-download.svg'
import helpers from "src/utils/helpers";
import utility, { moneyFormat } from "src/utils/utility";
import { capsule } from "../capsule";


export const transferReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Customer name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.senderAccountName}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Beneficiary name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.recipientAccountName}</AppText>,
        width: '20rem'
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount)}</AppText>,
        minWidth: '18rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Account number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.recipientAccountNumber}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Transfer type</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.transferType}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.transactionDateTime ? helpers.handleDate(row.transactionDateTime) : row?.transactionDateTime}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.transactionStatus === 'Successful' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.transactionStatus === 'Successful' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.transactionStatus}
                </AppSpan>
            </GridContainer>
        ),
    }
]

export const transferReportData = [
    {
        "sn": 1,
        "customerID": "000006",
        "profileID": 2,
        "profile": null,
        "senderAccountNumber": "1100000045",
        "senderAccountName": "Ridwan Kolawole",
        "recipientAccountNumber": "110000000034",
        "recipientAccountName": "Another",
        "recipientBankName": "GTBank",
        "recipientBankCode": null,
        "transactionDateTime": "2023-04-06T12:23:08",
        "transactionReference": "string",
        "amount": 1000,
        "narration": "Testing data api",
        "transactionStatus": "Successful",
        "responseCode": " ",
        "responseDescription": " ",
        "transferType": "Other bank",
        "authenticationType": "PINOnly",
        "scheduledTransferReference": null,
        "expenseCategory": null,
        "terminalType": 0,
        "id": 2,
        "ibInstitutionCode": 1
      },
      {
        "sn": 2,
        "customerID": "000006",
        "profileID": 2,
        "profile": null,
        "senderAccountNumber": "1100000045",
        "senderAccountName": "Some test",
        "recipientAccountNumber": "110000000034",
        "recipientAccountName": "Another",
        "recipientBankName": "GTBank",
        "recipientBankCode": null,
        "transactionDateTime": "2023-04-05T12:23:08",
        "transactionReference": "string",
        "amount": 1000,
        "narration": "Testing data api",
        "transactionStatus": "Successful",
        "responseCode": " ",
        "responseDescription": " ",
        "transferType": "Other bank",
        "authenticationType": "PINOnly",
        "scheduledTransferReference": null,
        "expenseCategory": null,
        "terminalType": 0,
        "id": 3,
        "ibInstitutionCode": 1
      },
      {
        "sn": 3,
        "customerID": "000006",
        "profileID": 2,
        "profile": null,
        "senderAccountNumber": "1100000045",
        "senderAccountName": "Some test",
        "recipientAccountNumber": "110000000034",
        "recipientAccountName": "Another",
        "recipientBankName": "GTBank",
        "recipientBankCode": null,
        "transactionDateTime": "2023-04-06T00:00:00",
        "transactionReference": "string",
        "amount": 1000,
        "narration": "Testing data api",
        "transactionStatus": "Successful",
        "responseCode": " ",
        "responseDescription": " ",
        "transferType": "Other bank",
        "authenticationType": "PINOnly",
        "scheduledTransferReference": null,
        "expenseCategory": null,
        "terminalType": 0,
        "id": 4,
        "ibInstitutionCode": 1
      },
      {
        "sn": 4,
        "customerID": "000005",
        "profileID": 25,
        "profile": null,
        "senderAccountNumber": "1100000054",
        "senderAccountName": "Awa",
        "recipientAccountNumber": "1234567899",
        "recipientAccountName": "Jane",
        "recipientBankName": "ACCESS BANK",
        "recipientBankCode": null,
        "transactionDateTime": "2023-04-17T16:59:54",
        "transactionReference": " ",
        "amount": 100,
        "narration": "Testing",
        "transactionStatus": "Successful",
        "responseCode": " ",
        "responseDescription": " ",
        "transferType": "OtherBank",
        "authenticationType": "PINOnly",
        "scheduledTransferReference": null,
        "expenseCategory": null,
        "terminalType": 0,
        "id": 5,
        "ibInstitutionCode": 1
      },
      {
        "sn": 5,
        "customerID": "000005",
        "profileID": 25,
        "profile": null,
        "senderAccountNumber": "1100000054",
        "senderAccountName": "Awa",
        "recipientAccountNumber": "1234567899",
        "recipientAccountName": "Jane",
        "recipientBankName": "ACCESS BANK",
        "recipientBankCode": null,
        "transactionDateTime": "2023-04-17T17:04:52",
        "transactionReference": " ",
        "amount": 100,
        "narration": "Testing",
        "transactionStatus": "Successful",
        "responseCode": " ",
        "responseDescription": " ",
        "transferType": "OtherBank",
        "authenticationType": "PINOnly",
        "scheduledTransferReference": null,
        "expenseCategory": null,
        "terminalType": 0,
        "id": 6,
        "ibInstitutionCode": 1
      },
]


// ====================== BILL PAYMENT REPORT =================

export const billPaymentReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Customer name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountName}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Payment item</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.paymentItemName}</AppText>,
        width: '25rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Biller category</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.billerCategoryName}</AppText>,
        width: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Biller</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.billerName}</AppText>,
        width: '20rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount)}</AppText>,
        minWidth: '18rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Account number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.dateLogged ? helpers.handleDate(row.dateLogged) : row?.dateLogged}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.status === 'Successful' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.status === 'Successful' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.status}
                </AppSpan>
            </GridContainer>
        ),
    }
]


// ================== AIRTIME REPORT ==============


export const airtimeReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Customer name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountName}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Account number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount)}</AppText>,
        minWidth: '18rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Network</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.network}</AppText>,
        width: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Phone number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.phoneNumber}</AppText>,
        width: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.transactionDateTime ? helpers.handleDate(row.transactionDateTime) : row?.transactionDateTime}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.status === 'Successful' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.status === 'Successful' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.status}
                </AppSpan>
            </GridContainer>
        ),
    }
]

// ====================CUSTOMER PROFILE REPORT ============

export const customerProfileReportColumn = (handleView: any) => {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Username</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.username}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Customer ID</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">First Name</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.firstName}</AppText>,
            width: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Last Name</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.lastName}</AppText>,
            width: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Primary Account</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.primaryAccount}</AppText>,
            width: '20rem'
        },
        {
            name: <p className="table-column-title">Phone Number</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.phoneNumber}</AppText>,
            wrap: true,
            minWidth: '17rem'
        },
        {
            name: <p className="table-column-title">Email Address</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.emailAddress}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Full Address</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.fullAddress}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Creation Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.creationDate ? helpers.handleDate(row.creationDate) : row?.creationDate}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Last Login Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.lastLoginDate ? helpers.handleDate(row.lastLoginDate) : row?.lastLoginDate}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Onboarding Method</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.onboardingMethod}</AppText>,
            wrap: true,
            minWidth: '17rem'
        },
        {
            name: <p className="table-column-title">Blocked Transactions</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.blockTransactions ? 'Yes' : 'No'}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Gender</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.gender}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Referral Code</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.referralCode}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Status</p>,
            minWidth: '17rem',
            selector:(row: any) => (
                <GridContainer 
                    width='auto' sizeUnit="" minHeight='2.2'
                    bgColor={row.isOnboardingCompleted ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.isOnboardingCompleted ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='500'
                    >
                        {row.isOnboardingCompleted ? 'Completed' : 'Pending'}
                    </AppSpan>
                </GridContainer>
            ),
        },
        {
            name: <p className="table-column-title">Onboarding Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.creationDate ? helpers.handleDate(row.creationDate) : row?.creationDate}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Locked Status</p>,
            minWidth: '18rem',
            selector:(row: any) => (
                <GridContainer 
                    width='auto' sizeUnit="" minHeight='2.2'
                    bgColor={!row.isLocked ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={!row.isLocked ? '#12B76A' : '#D30202'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='500'
                    >
                        {!row.isLocked ? 'Unlocked' : 'Locked'}
                    </AppSpan>
                </GridContainer>
            ),
        },
        {
            selector:(row: any) => 
                <AppText 
                    textSize='1.4' color="#0D968F" 
                    hoverColor="#667085" cursor="pointer"
                    onClick={() => handleView(row)}
                >
                    View Details
                </AppText>,
            minWidth: '12rem'
        }
    ]
}

export const customerProfileReportData = [
    {
        username: "Rhydhur",
        customerID: "360002892",
        onboardingDate: '2023-04-10T03:34:10',
        lastLoginDate: '2023-04-10T03:34:10',
        creationDate: '2023-04-10T03:34:10',
        status: 'Active',
        referralCode: 'Tw762722',
        gender: 'Male',
        blockedTransaction: 'Lorem ipsum',
        address: 'Lorem ipsum',
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        username: "Rhydhur",
        customerID: "360002892",
        onboardingDate: '2023-04-10T03:34:10',
        lastLoginDate: '2023-04-10T03:34:10',
        creationDate: '2023-04-10T03:34:10',
        status: 'Inactive',
        referralCode: 'Tw762722',
        gender: 'Male',
        blockedTransaction: 'Lorem ipsum',
        address: 'Lorem ipsum',
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        username: "Rhydhur",
        customerID: "360002892",
        onboardingDate: '2023-04-10T03:34:10',
        lastLoginDate: '2023-04-10T03:34:10',
        creationDate: '2023-04-10T03:34:10',
        status: 'Active',
        referralCode: 'Tw762722',
        gender: 'Male',
        blockedTransaction: 'Lorem ipsum',
        address: 'Lorem ipsum',
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        username: "Rhydhur",
        customerID: "360002892",
        onboardingDate: '2023-04-10T03:34:10',
        lastLoginDate: '2023-04-10T03:34:10',
        creationDate: '2023-04-10T03:34:10',
        status: 'Active',
        referralCode: 'Tw762722',
        gender: 'Male',
        blockedTransaction: 'Lorem ipsum',
        address: 'Lorem ipsum',
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    }
]


// ====================== CUSTOMER LOGIN =============

export const customerLoginReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Username</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.username}</AppText>,
        wrap: true,
        minWidth: '12rem'
    },
    {
        name: <p className="table-column-title">Device ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.deviceID}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Platform</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.isMobile ? 'MOBILE' : 'WEB'}</AppText>,
        width: '12rem'
    },
    {
        name: <p className="table-column-title">Login Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.timeAttempted ? helpers.handleDate(row.timeAttempted) : row?.timeAttempted}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.isSuccessful ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.isSuccessful ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.isSuccessful ? 'Successful' : 'Failed'}
                </AppSpan>
            </GridContainer>
        ),
    },
]

export const customerLoginReportData = [
    {
        username: 'Elnino',
        deviceID: '199289ahw7892',
        customerID: '360002892',
        loginDate: '2023-04-10T03:34:10'
    },
    {
        username: 'Rhydhur',
        deviceID: '199289ahw7892',
        customerID: '360002892',
        loginDate: '2023-04-10T03:34:10'
    },
    {
        username: 'CR7',
        deviceID: '199289ahw7892',
        customerID: '360002892',
        loginDate: '2023-04-10T03:34:10'
    },
    {
        username: 'Elnino',
        deviceID: '199289ahw7892',
        customerID: '360002892',
        loginDate: '2023-04-10T03:34:10'
    }
]

// ================== FAILED TX REPORT ===============

export const failedTxReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Transaction Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.transactionDate ? helpers.handleDate(row.transactionDate) : row?.transactionDate}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Transaction Reference</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.transactionReference}</AppText>,
        wrap: true,
        minWidth: '20rem'
    },
    {
        name: <p className="table-column-title">Profile ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.profileID}</AppText>,
        wrap: true,
        minWidth: '12rem'
    },
    {
        name: <p className="table-column-title">Account Number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        width: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Beneficiary Name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.beneficiaryName}</AppText>,
        width: '25rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount, true)}</AppText>,
        width: '15rem'
    },
    // {
    //     name: <p className="table-column-title">Status</p>,
    //     minWidth: '20rem',
    //     selector:(row: any) => (
    //         <GridContainer 
    //             width='auto' sizeUnit="" minHeight='2.2'
    //             bgColor={row.status === 'Disbursed' ? '#F9F5FF' : row.status === 'Expired' ? '#FFF5F5' : '#FEF0C7'} 
    //             topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
    //         >
    //             <AppSpan 
    //                 color={row.status === 'Disbursed' ? '#5925DC' : row.status === 'Expired' ? '#DC2525' : '#B54708'} 
    //                 textSize='1.2' leftPadding='0.5' fontWeight='500'
    //             >
    //                 {row.status}
    //             </AppSpan>
    //         </GridContainer>
    //     ),
    // }
]

export const failedTxReportData = [
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002892',
        accountNumber: '0014337218',
        beneficiaryName: 'John Doe',
        amount: '250000',
        status: 'Expired'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002892',
        accountNumber: '0014337218',
        beneficiaryName: 'John Doe',
        amount: '250000',
        status: 'Awaiting Confirmation'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002892',
        accountNumber: '0014337218',
        beneficiaryName: 'John Doe',
        amount: '250000',
        status: 'Expired'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002892',
        accountNumber: '0014337218',
        beneficiaryName: 'John Doe',
        amount: '250000',
        status: 'Disbursed'
    }
]

// ===================== TX LIMIT REPORT =============

export const txLimitReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Profile ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.profileID}</AppText>,
        wrap: true,
        minWidth: '12rem'
    },
    {
        name: <p className="table-column-title">Account Number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        wrap: true,
        minWidth: '15rem'
    },
    {
        name: <p className="table-column-title">Single Transfer Limit (₦)</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{moneyFormat(row.oneTimeLimit, true)}</AppText>,
        wrap: true,
        minWidth: '12rem'
    },
    {
        name: <p className="table-column-title">Daily Transfer Limit (₦)</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{moneyFormat(row.dailyLimit, true)}</AppText>,
        wrap: true,
        minWidth: '12rem'
    }
]

export const txLimitReportData = [
    {
        customerID: '360002892',
        singleTransferLimit: 'XXXXXXXXX',
        dailyTransferLimit: 'XXXXXXXXX',
        dateCreated: '2023-04-10T03:34:10'
    },
    {
        customerID: '3600078892',
        singleTransferLimit: 'XXXXXXXXX',
        dailyTransferLimit: 'XXXXXXXXX',
        dateCreated: '2023-04-10T03:34:10'
    },
    {
        customerID: '360002820',
        singleTransferLimit: 'XXXXXXXXX',
        dailyTransferLimit: 'XXXXXXXXX',
        dateCreated: '2023-04-10T03:34:10'
    },
    {
        customerID: '360002492',
        singleTransferLimit: 'XXXXXXXXX',
        dailyTransferLimit: 'XXXXXXXXX',
        dateCreated: '2023-04-10T03:34:10'
    }
]

// ================== FIXED DEPOSIT ================

export const fixedDepositReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Account Number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        wrap: true,
        minWidth: '20rem',
    },
    {
        name: <p className="table-column-title">Product Code</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.productCode}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount, true)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Interest Rate</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.interestRate}%</AppText>,
        wrap: true,
        minWidth: '12rem',
    },
    {
        name: <p className="table-column-title">Tenure</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.tenureInDays} Days</AppText>,
        wrap: true,
        minWidth: '12rem',
    },
    {
        name: <p className="table-column-title">Maturation Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.maturationDate ? helpers.handleDate(row.maturationDate) : row?.maturationDate}</AppText>,
        wrap: true,
        minWidth: '20rem',
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.accountStatus === 'Active' ? '#F9F5FF' : row.accountStatus === 'Closed' ? '#FFF5F5' : '#FEF0C7'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.accountStatus === 'Active' ? '#5925DC' : row.accountStatus === 'Closed' ? '#DC2525' : '#B54708'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.accountStatus}
                </AppSpan>
            </GridContainer>
        ),
    }
]

export const fixedDepositReportData = [
    {
        accountNumber: '0014337281',
        productCode: 'XXXXXXXX',
        amount: '35000000',
        interestRate: '3.5',
        tenure: 30,
        dateCreated: '2023-04-10T03:34:10',
        status: 'Closed'
    },
    {
        accountNumber: '0014337281',
        productCode: 'XXXXXXXX',
        amount: '35000000',
        interestRate: '3.5',
        tenure: 30,
        dateCreated: '2023-04-10T03:34:10',
        status: 'Active'
    },
    {
        accountNumber: '0014337281',
        productCode: 'XXXXXXXX',
        amount: '35000000',
        interestRate: '3.5',
        tenure: 30,
        dateCreated: '2023-04-10T03:34:10',
        status: 'Closed'
    },
    {
        accountNumber: '0014337281',
        productCode: 'XXXXXXXX',
        amount: '35000000',
        interestRate: '3.5',
        tenure: 30,
        dateCreated: '2023-04-10T03:34:10',
        status: 'Active'
    }
]

// ============= CARDLESS WITHDRAWAL REPORT ============

export const cardlessWithdrawalReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.date ? helpers.handleDate(row.date) : row?.date}</AppText>,
        wrap: true,
        minWidth: '20rem',
    },
    {
        name: <p className="table-column-title">Customer Name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerName}</AppText>,
        minWidth: '20rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.amount)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Status</p>,
        minWidth: '20rem',
        selector:(row: any) => (
            <GridContainer 
                width='auto' sizeUnit="" minHeight='2.2'
                bgColor={row.status === 'Disbursed' ? '#F9F5FF' : row.status === 'Expired' ? '#FFF5F5' : '#FEF0C7'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.status === 'Disbursed' ? '#5925DC' : row.status === 'Expired' ? '#DC2525' : '#B54708'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='500'
                >
                    {row.status}
                </AppSpan>
            </GridContainer>
        ),
    }
]


export const cardlessWithdrawalReportData = [
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Disbursed'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Awaiting Confrimation'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Disbursed'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Expired'
    }
]

// ================== LOANS REPORT ===============

export const loansReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.dateCreated ? helpers.handleDate(row.dateCreated) : row?.dateCreated}</AppText>,
        wrap: true,
        minWidth: '20rem',
    },
    {
        name: <p className="table-column-title">Customer Name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerName}</AppText>,
        minWidth: '25rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Customer ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.loanAmount)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (<p className='text-[#ccc] '>{capsule(row.realLoanStatus)}</p>),
        minWidth: '15rem',
        wrap: true
    },
]


export const loansReportData = [
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Disbursed'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Awaiting Confrimation'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Disbursed'
    },
    {
        date: '2023-04-10T03:34:10',
        customerName: 'Ridwan Kolawole',
        customerID: '360002492',
        amount: '33500',
        status: 'Expired'
    }
]

// ============ TARGET SAVINGS REPORT ============

export const targetSavingsReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Account Number</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Target Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.targetAmount, true)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Debit Amount</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">₦{moneyFormat(row.debitAmount, true)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Interest Rate</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.interestRate}%</AppText>,
        wrap: true,
        minWidth: '12rem',
    },
    {
        name: <p className="table-column-title">Tenure</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.tenure} Days</AppText>,
        wrap: true,
        minWidth: '12rem',
    },
    {
        name: <p className="table-column-title">Frequency</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.frequency}</AppText>,
        wrap: true,
        minWidth: '12rem',
    },
    {
        name: <p className="table-column-title">Maturity Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.maturityDate ? helpers.handleDate(row.maturityDate) : row?.maturityDate}</AppText>,
        wrap: true,
        minWidth: '20rem',
    },
    {
        name: <p className="table-column-title">Start Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.startDate ? helpers.handleDate(row.startDate) : row?.startDate}</AppText>,
        wrap: true,
        minWidth: '20rem',
    }
]

export const targetSavingsReportDate = [
    {
        accountNumber: '0123456789',
        targetAmount: '420000',
        debitAmount: '15000',
        interestRate: '2.3',
        tenure: '90',
        frequency: 'XXXXXXXX',
        maturityDate: '2023-04-10T03:34:10',
        startDate: '2023-04-10T03:34:10'
    },
    {
        accountNumber: '0123456789',
        targetAmount: '420000',
        debitAmount: '15000',
        interestRate: '2.3',
        tenure: '90',
        frequency: 'XXXXXXXX',
        maturityDate: '2023-04-10T03:34:10',
        startDate: '2023-04-10T03:34:10'
    },
    {
        accountNumber: '0123456789',
        targetAmount: '420000',
        debitAmount: '15000',
        interestRate: '2.3',
        tenure: '90',
        frequency: 'XXXXXXXX',
        maturityDate: '2023-04-10T03:34:10',
        startDate: '2023-04-10T03:34:10'
    }
]

// =============== Institutions ====================

export const institutionsColumn = (handleView: any) => {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Institution</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.name}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">BankOne Code</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.institutionCode}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">IB Institution Code</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.id}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Alias</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.alias}</AppText>,
            width: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Phone Number</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.correspondencePhone}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Email Address</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.correspondenceEmail}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Setup Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.setupDate ? helpers.handleDate(row.setupDate) : row?.setupDate}</AppText>,
            wrap: true,
            minWidth: '22rem',
        },
        {
            selector:(row: any) => 
                <AppText 
                    textSize='1.4' color="#0D968F" 
                    hoverColor="#667085" cursor="pointer"
                    onClick={() => handleView(row)}
                >
                    View Details
                </AppText>,
            minWidth: '12rem',
        },
    ]
}

export const institutionsData = [
    {
        institutionName: 'Fidelity Bank',
        username: "Rhydhur",
        nipCode: "cBG-Xyz",
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        institutionName: 'Fidelity Bank',
        username: "Rhydhur",
        nipCode: "cBG-Xyz",
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        institutionName: 'Fidelity Bank',
        username: "Rhydhur",
        nipCode: "cBG-Xyz",
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    },
    {
        institutionName: 'Fidelity Bank',
        username: "Rhydhur",
        nipCode: "cBG-Xyz",
        email: 'test@gmail.com',
        phoneNumber: 'XXXXXXXX',
        primaryAccount: 'Mobile',
        firstName: "Ridwan",
        lastName: 'Kolawole'
    }
]


// ============ AUDIT REPORT =====================

export const auditReportColumn = (handleView: any) => {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">User ID</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.userID}</AppText>,
            minWidth: '17rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Action performed</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.action}</AppText>,
            wrap: true,
            minWidth: '22rem',
        },
        {
            name: <p className="table-column-title">Model</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.model}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">Activity start date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.loggedAt ? helpers.handleDate(row.loggedAt) : row?.loggedAt}</AppText>,
            wrap: true,
            minWidth: '20rem',
        },
        {
            name: <p className="table-column-title">Activity end date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.loggedAt ? helpers.handleDate(row.loggedAt) : row?.loggedAt}</AppText>,
            wrap: true,
            minWidth: '20rem',
        },
        {
            selector:(row: any) => 
                <AppText 
                    textSize='1.4' color="#0D968F" 
                    hoverColor="#667085" cursor="pointer"
                    onClick={() => handleView(row)}
                >
                    View Details
                </AppText>,
            minWidth: '12rem'
        },
    ]
}


export const auditReportData = [
    {
        date: '2023-04-10T03:34:10',
        username: 'Ridwan Kolawole',
        actionPerformed: 'I created an institution with all configurations',
        processOutput: 'Null'
    },
    {
        date: '2023-04-10T03:34:10',
        username: 'Ridwan Kolawole',
        actionPerformed: 'I created an institution with all configurations',
        processOutput: 'Null'
    },
    {
        date: '2023-04-10T03:34:10',
        username: 'Ridwan Kolawole',
        actionPerformed: 'I created an institution with all configurations',
        processOutput: 'Null'
    },
    {
        date: '2023-04-10T03:34:10',
        username: 'Ridwan Kolawole',
        actionPerformed: 'I created an institution with all configurations',
        processOutput: 'Null'
    }
]


// =================== LOGIN AUDIT REPORT =============

export const loginReportColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Username</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.username}</AppText>,
        minWidth: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">User ID</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.userID}</AppText>,
        minWidth: '17rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Login time</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.timeAttempted ? helpers.splitDateTime(row.timeAttempted).completeTime : row?.timeAttempted}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Login Date</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.timeAttempted ? helpers.splitDateTime(row.timeAttempted).date : row?.timeAttempted}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    // {
    //     name: <p className="table-column-title">Logout date</p>,
    //     selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.logoutDate ? helpers.handleDate(row.logoutDate) : row?.logoutDate}</AppText>,
    //     wrap: true,
    //     minWidth: '20rem',
    // },
]


export const loginReportData = [
    {
        loginDate: '2023-04-10T03:34:10',
        username: 'Rhydhur',
        logoutDate: '2023-04-10T03:38:10'
    },
    {
        loginDate: '2023-04-10T03:34:10',
        username: 'Rhydhur',
        logoutDate: '2023-04-10T03:38:10'
    },
    {
        loginDate: '2023-04-10T03:34:10',
        username: 'Rhydhur',
        logoutDate: '2023-04-10T03:38:10'
    },
    {
        loginDate: '2023-04-10T03:34:10',
        username: 'Rhydhur',
        logoutDate: '2023-04-10T03:38:10'
    }
]


// ================== USERS===================

export const usersColumn = (handleRepushEmail?: any) => {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Username</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.username}</AppText>,
            minWidth: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Full name</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.firstName} {row.lastName}</AppText>,
            minWidth: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Email</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.email}</AppText>,
            minWidth: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Phone number</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.phoneNumber}</AppText>,
            minWidth: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Role</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{utility.getRoleName(row.role)}</AppText>,
            minWidth: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Institution Code</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.ibInstitutionCode}</AppText>,
            minWidth: '15rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Creation Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.creationDate ? helpers.splitDateTime(row.creationDate).date : row?.creationDate}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        // {
        //     name: <p className="table-column-title">Changed password</p>,
        //     selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.isPasswordChanged ? 'TRUE' : 'FALSE'}</AppText>,
        //     minWidth: '12rem',
        //     wrap: true,
        // },
        {
            selector:(row: any) => 
                (handleRepushEmail && !row.isPasswordChanged) ? 
                <AppText 
                    textSize='1.4' color="#0D968F" 
                    hoverColor="#667085" cursor="pointer"
                    onClick={() => handleRepushEmail(row)}
                >
                    Re-push Email
                </AppText>
                : null,
            minWidth: '15rem',
        }
    ]
}


export const usersData = [
    {
        fullName: 'Ridwan Kolawole',
        username: 'Rhydhur',
        email: 'example@gmaIL.com',
        phoneNumber: '08062718291',
        isActive: true,
        isUnlocked: true
    },
    {
        fullName: 'Ridwan Kolawole',
        username: 'Rhydhur',
        email: 'example@gmaIL.com',
        phoneNumber: '08062718291',
        isActive: false,
        isUnlocked: false
    },
    {
        fullName: 'Ridwan Kolawole',
        username: 'Rhydhur',
        email: 'example@gmaIL.com',
        phoneNumber: '08062718291',
        isActive: true,
        isUnlocked: false
    },
    {
        fullName: 'Ridwan Kolawole',
        username: 'Rhydhur',
        email: 'example@gmaIL.com',
        phoneNumber: '08062718291',
        isActive: true,
        isUnlocked: true
    }
]


export const accountUpgradeRequestColumn = (handleView: any) => {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.sn}</AppText>,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Customer ID</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.customerID}</AppText>,
            minWidth: '17rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Account Number</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.accountNumber}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">NIN</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.nin}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">BVN</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.bvn}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">Status</p>,
            minWidth: '20rem',
            selector:(row: any) => (
                <GridContainer 
                    width='auto' sizeUnit="" minHeight='2.2'
                    bgColor={row.status === 'COMPLETED' ? '#ECFDF3' : row.status === 'REJECTED' ? '#FFF5F5' : '#FEF0C7'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.status === 'COMPLETED' ? '#027A48' : row.status === 'REJECTED' ? '#DC2525' : '#B54708'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='500'
                    >
                        {row.status}
                    </AppSpan>
                </GridContainer>
            ),
        },
        {
            name: <p className="table-column-title">Request Date</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.requestedAt ? helpers.handleDate(row.requestedAt) : row?.requestedAt}</AppText>,
            wrap: true,
            minWidth: '20rem',
        },
        {
            name: <p className="table-column-title">Is Upgraded</p>,
            selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.isUpgraded ? 'TRUE' : 'FALSE'}</AppText>,
            wrap: true,
            minWidth: '15rem',
        },
        {
            selector:(row: any) => 
                <AppText 
                    textSize='1.4' color="#0D968F" 
                    hoverColor="#667085" cursor="pointer"
                    onClick={() => handleView(row)}
                >
                    View Details
                </AppText>,
            minWidth: '12rem'
        }
    ]
}

export const accountUpgradeRequestData = [
    {
        id: 1,
        customerID: '17872782',
        accountNumber: '0829237828',
        bvn: '2238399393',
        nin: '8919272982',
        status: 'COMPLETED',
        requestedAtStartTime: '2024-08-19T09:58:32.089Z',
        isUpgraded: true
    },
    {
        id: 2,
        customerID: '17872782',
        accountNumber: '0829237828',
        bvn: '2238399393',
        nin: '8919272982',
        status: 'PENDING',
        requestedAtStartTime: '2024-08-19T09:58:32.089Z',
        isUpgraded: false
    }
]


export const buildReportColumn =()=> {
  return  [
    {
        name: <p className="table-column-title">Institution Code</p>,
        selector: (row: any) => <AppText textSize='1.4' color="#667085">{row.ibInstitutionCode}</AppText>,
        sortable: true,
        width: '15rem'
    },
    {
        name: <p className="table-column-title">Institution Name</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.institutionName}</AppText>,
        wrap: true,
        minWidth: '35rem',
    },
    {
        name: <p className="table-column-title">Build Type</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row.buildType}</AppText>,
        minWidth: '15rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{capsule(row.statusMessage)}</AppText>,
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Download Build</p>,
        selector:(row: any) => (
        <div style={{width:'100px',display:'flex', justifyContent:'center'}}>
            {!!row.lastBuildUrl ?
             (<a 
                href={`https:/${row.lastBuildUrl}`} rel="noreferrer" target="_blank"  
                className="download-apk-btn" >
                <img src={ApkDownloadIcon} width={20} height={20} alt="download"/>
            </a>):
            (<div className='download-apk-btn-disabled' >
                <img src={ApkDownloadIcon} width={20} height={20} alt="download"/>
            </div>)}
        </div>
        ),
        wrap: true,
        minWidth: '15rem',
    },
    {
        name: <p className="table-column-title">Last Build Start Date/Time</p>,
        selector:(row: any) => <AppText textSize='1.4' color="#667085">{row?.currentBuildStartDateTime ? helpers.handleDate(row.currentBuildStartDateTime) : row?.currentBuildStartDateTime}</AppText>,
        wrap: true,
        minWidth: '25rem',
    },
    {
        name: <p className="table-column-title">Last Build Completion Date/Time</p>,
        selector:(row: any) => (<AppText textSize='1.4' color="#667085">{row?.currentBuildCompletionDateTime ? helpers.handleDate(row.currentBuildCompletionDateTime) : row?.currentBuildCompletionDateTime}</AppText>),
        minWidth: '25rem',
        wrap: true
    },
]
}