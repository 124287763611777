import { useState, useContext } from "react";
import helpers from "src/utils/helpers"
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from "src/provider/API/call-service";
import { AbsoluteContainer, AppLabel, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, FormGroup, SidePopupContainer } from "src/style"
import UploadIcon from 'src/assets/img/upload-icon.svg'


export const AddNewFeed: React.FC<any> = ({getUpdatedFeeds, isWeb, close}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState({webEncodedImage: '', webImageExtension: '', linkUrl: '', mobileEncodedImage: '', mobileImageExtension: ''})

    async function handleFile (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files instanceof FileList) {
            if(!helpers.isValidFileType(e.target.value)) return notifier.show('Only SVG, PNG, and JPEG files are allowed')

            const extension = helpers.getFileExtension(e.target.value)

            const uploadedFile = await helpers.compressImage(e.target.files[0])
            if (!uploadedFile) return notifier.show('Unable to compress large file size uploaded')

            helpers.convertFileToBase64(uploadedFile)
            .then(async (result) => {
                setInput({...input, [e.target.name]: result, [e.target.alt]: extension})
            })
            .catch(err => {
                notifier.show(JSON.stringify(err), 'Validation Error')
            })
        }
    }

    async function handleSubmit () {
        if (!input.linkUrl) return notifier.show("Kindly provide feed's link URL")
        if (!input.webEncodedImage && !input.mobileEncodedImage) return notifier.show("Kindly provide feed's Image")

        const response = await API.addFeed(input)
        if (response && getUpdatedFeeds) getUpdatedFeeds() 

        close()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    New Advertisment
                </AppText>
                <CustomContainer>
                    <FlexColumn 
                        borderColor='#EAECF0' radius='0.8' minHeight='12.6' topMargin='2'
                        padding='1.5'
                    >
                        <img src={UploadIcon} />
                        <input type="file" name={isWeb ? 'webEncodedImage' : 'mobileEncodedImage'} alt={isWeb ? 'webImageExtension' : 'mobileImageExtension'} onChange={handleFile} className="logo-input" id="web-img" />
                        <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='web-img'>Click to upload</AppLabel>
                        <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>({isWeb ? '850 X 200' : '350 x 100'}) Preferred</AppSpan>
                    </FlexColumn>
                    { (input.webEncodedImage || input.mobileEncodedImage) &&
                        <AppText align="center" color="#0D968F" topMargin="1" textSize="1.2">Image uploaded Successfully</AppText>
                    }
                </CustomContainer>
                <FormGroup topMargin="2">
                    <label>Feed link</label>
                    <input 
                        placeholder='Enter feed link'
                        name='linkUrl'
                        value={input.linkUrl}
                        onChange={(e) => setInput({...input, linkUrl: e.target.value})}
                        autoComplete="off"
                    />
                </FormGroup>
                <FlexRow justifyContent='flex-end' topMargin='2'>
                    <Button
                        width='8.2'
                        titleSize='1.4' fontWeight='600'
                        height='4' radius='0.8'
                        bottomMargin='1' hoverColor='#0D968F'
                        hoverBgColor='#fff' borderColor='#0D968F'
                        onClick={handleSubmit}
                    >
                        Publish
                    </Button>
                </FlexRow>
            </FlexColumn>
        </>
    )
}