import { useContext } from "react"
import AppInfoContext from "src/provider/state-manager/appInfoProvider"

function SvgComponent(props: any) {
    let theme = JSON.parse(localStorage.getItem('theme')!) || {}

    return (
        <svg
            width={412}
            height={301}
            viewBox="0 0 412 301"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill='#C9F3F0' d="M127.756 0H411.308V231.834H127.756z" />
            <path fill='#0EA59D' d="M127.756 0H411.308V26.0243H127.756z" />
            <path
                d="M369.938 16.12a3.108 3.108 0 100-6.216 3.108 3.108 0 000 6.216z"
                fill="#50CC65"
            />
            <path
                d="M381.657 16.12a3.108 3.108 0 100-6.216 3.108 3.108 0 000 6.216z"
                fill="#F9C32B"
            />
            <path
                d="M393.375 16.12a3.108 3.108 0 100-6.215 3.108 3.108 0 000 6.215z"
                fill="#90CEF4"
            />
            <path
                d="M411.308 26.025l-283.552 205.81 283.727.003-.175-205.813z"
                fill="#E1FFFD"
            />
            <path
                fill="#0EA59D"
                d="M139.693 38.5898H167.65030000000002V49.4524H139.693z"
            />
            <path
                d="M340.238 123.392c0 22.726-11.331 42.809-28.648 54.901-10.845 7.575-24.035 12.017-38.27 12.017-36.955 0-66.918-29.963-66.918-66.918 0-14.155 4.391-27.276 11.892-38.087 12.08-17.42 32.221-28.83 55.026-28.83 36.961 0 66.918 29.956 66.918 66.917z"
                fill="#D91111"
            />
            <path
                d="M323.487 140.197c0 14.156-4.397 27.282-11.897 38.093-10.845 7.575-24.035 12.017-38.27 12.017-36.955 0-66.918-29.963-66.918-66.918 0-14.155 4.391-27.276 11.892-38.087 10.845-7.58 24.04-12.023 38.275-12.023 36.955 0 66.918 29.958 66.918 66.918z"
                fill="#D91111"
            />
            <path
                d="M274.903 152.409a5.097 5.097 0 01-5.096-5.096v-60.55a5.097 5.097 0 1110.193 0v60.55a5.097 5.097 0 01-5.097 5.096z"
                fill="#FFEEF2"
            />
            <path
                d="M313.467 296.213l-1.518-.408 1.042-3.869 1.518.408a1.59 1.59 0 011.122 1.949l-.214.798a1.591 1.591 0 01-1.95 1.122z"
                fill="#3AAF4B"
            />
            <path
                d="M112.745 152.002h-2.946a1.868 1.868 0 01-1.869-1.869v-2.946c0-1.032.836-1.869 1.869-1.869h2.946a1.87 1.87 0 011.869 1.869v2.946a1.869 1.869 0 01-1.869 1.869zM124.09 152.002h-2.946a1.869 1.869 0 01-1.869-1.869v-2.946a1.87 1.87 0 011.869-1.869h2.946c1.033 0 1.869.837 1.869 1.869v2.946a1.868 1.868 0 01-1.869 1.869zM135.432 152.002h-2.946a1.869 1.869 0 01-1.869-1.869v-2.946a1.87 1.87 0 011.869-1.869h2.946a1.87 1.87 0 011.869 1.869v2.946a1.869 1.869 0 01-1.869 1.869z"
                fill="#D3EDD5"
            />
            <path
                d="M310.226 252.218c-.002-.062-.251-6.405-3.119-12.607-3.708-8.023-9.92-12.091-18.464-12.091-8.568 0-14.743 4.092-18.354 12.164-2.774 6.201-2.94 12.453-2.942 12.515l-3.429-.075c.006-.28.884-28.034 24.725-28.034 23.824 0 25.003 27.736 25.011 28.016l-3.428.112z"
                fill="#D5D38D"
            />
            <path
                d="M306.079 252.163h-7.901l-8.529-22.13-11.834 22.13h-9.651l22.028-41.202 15.887 41.202z"
                fill="#0EA59D"
            />
            <path
                d="M274.65 167.033a5.373 5.373 0 100-10.746 5.373 5.373 0 000 10.746z"
                fill="#FFEEF2"
            />
            <path
                d="M286.685 248.003h-7.138v-19.319c0-1.156.937-2.094 2.094-2.094h2.95c1.156 0 2.094.937 2.094 2.094v19.319z"
                fill="#25C5BC"
            />
            <path
                d="M281.636 243.631a.59.59 0 01-.589-.589v-11.421a.59.59 0 111.179 0v11.421a.59.59 0 01-.59.589zM284.595 243.631a.59.59 0 01-.589-.589v-11.421a.59.59 0 111.179 0v11.421a.59.59 0 01-.59.589z"
                fill="#29A337" // later
            />
            <path
                fill="#D3EDD5"
                d="M281.93 248.002H284.30046V259.75280000000004H281.93z"
            />
            <path
                d="M304.888 236.899l-18.067-4.861a2.752 2.752 0 01-1.943-3.373l.803-2.985a2.752 2.752 0 013.373-1.942l18.067 4.861-2.233 8.3z"
                fill="#0E4915"
            />
            <path
                transform="rotate(15.058 291.791 233.383)"
                fill="#25C5BC"
                d="M291.791 233.383H297.48281V266.7206H291.791z"
            />
            <path fill="#F1F0B7" d="M262.344 252.164H315.2267V289.7974H262.344z" />
            <path
                d="M262.344 252.164l52.882 37.633v-37.633h-52.882z"
                fill="#D5D38D"
            />
            <path
                d="M137.088 109.678H12.69C5.958 109.678.5 104.22.5 97.488V77.874c0-6.733 5.458-12.19 12.19-12.19h124.397c6.733 0 12.191 5.457 12.191 12.19v19.614c0 6.732-5.458 12.19-12.19 12.19z"
                fill="#0EA59D"
            />
            <path
                d="M137.088 289.797H12.69c-6.732 0-12.19-5.458-12.19-12.191V126.491c0-6.732 5.458-12.19 12.19-12.19h124.397c6.733 0 12.191 5.458 12.191 12.19v151.115c0 6.733-5.458 12.191-12.19 12.191z"
                fill="#E1FFFD"
            />
            <path
                d="M129.324 192.162H79.842a9.023 9.023 0 01-9.024-9.023v-49.483a9.023 9.023 0 019.024-9.023h49.482a9.023 9.023 0 019.023 9.023v49.482a9.022 9.022 0 01-9.023 9.024z"
                fill="#BEE6E3"
            />
            <path
                d="M80.275 142.186a4.298 4.298 0 100-8.596 4.298 4.298 0 000 8.596z"
                fill="#000"
            />
            <path
                d="M96.482 142.186a4.298 4.298 0 100-8.596 4.298 4.298 0 000 8.596z"
                fill="#000"
                fillOpacity={0.25}
            />
            <path
                d="M112.687 142.186a4.298 4.298 0 100-8.595 4.298 4.298 0 000 8.595z"
                fill="#000"
            />
            <path
                d="M128.892 142.186a4.298 4.298 0 100-8.595 4.298 4.298 0 000 8.595z"
                fill="#C4C4C4"
            />
            <path
                d="M97.558 167.353h-9.007a4.453 4.453 0 01-4.453-4.453v-9.007a4.453 4.453 0 014.453-4.454h9.007a4.453 4.453 0 014.453 4.454v9.007a4.453 4.453 0 01-4.453 4.453zM120.356 167.353h-8.482a4.715 4.715 0 01-4.716-4.716v-8.482a4.716 4.716 0 014.716-4.716h8.482a4.716 4.716 0 014.715 4.716v8.482a4.715 4.715 0 01-4.715 4.716z"
                fill="#263238"
            />
            <path
                d="M131.054 176.276h-52.94a2.137 2.137 0 110-4.274h52.939a2.137 2.137 0 01.001 4.274z"
                fill="#2C2C2C"
            />
            <path
                d="M89.042 186.756h-2.947a1.868 1.868 0 01-1.868-1.869v-2.946c0-1.032.836-1.869 1.868-1.869h2.947a1.87 1.87 0 011.868 1.869v2.946a1.868 1.868 0 01-1.868 1.869zM100.385 186.756h-2.946a1.868 1.868 0 01-1.869-1.869v-2.946a1.87 1.87 0 011.87-1.869h2.945c1.033 0 1.869.837 1.869 1.869v2.946a1.868 1.868 0 01-1.869 1.869zM111.727 186.756h-2.946a1.869 1.869 0 01-1.869-1.869v-2.946a1.87 1.87 0 011.869-1.869h2.946a1.87 1.87 0 011.869 1.869v2.946a1.869 1.869 0 01-1.869 1.869zM123.073 186.756h-2.946a1.868 1.868 0 01-1.869-1.869v-2.946c0-1.032.836-1.869 1.869-1.869h2.946a1.87 1.87 0 011.869 1.869v2.946a1.869 1.869 0 01-1.869 1.869z"
                fill="#000"
                fillOpacity={0.25}
            />
            <path fill="#0EA59D" d="M14.4121 114.301H21.65352V289.797H14.4121z" />
            <path fill="#0EA59D" d="M30.6172 114.301H37.85862V289.797H30.6172z" />
            <path
                fill="#0EA59D" 
                d="M46.8242 114.301H54.065619999999996V289.797H46.8242z"
            />
            <path
                d="M107.894 252.164h-9.007a4.453 4.453 0 01-4.453-4.453v-9.008a4.453 4.453 0 014.453-4.453h9.007a4.453 4.453 0 014.453 4.453v9.008a4.453 4.453 0 01-4.453 4.453z"
                fill="#0EA59D" 
            />
            <path
                d="M103.393 245.552a2.346 2.346 0 100-4.692 2.346 2.346 0 000 4.692z"
                fill="#F79200"
            />
            <path
                d="M57.286 91.778H17.267a4.097 4.097 0 110-8.194h40.02a4.097 4.097 0 110 8.194z"
                fill="#E1FFFD"
            />
            <path
                d="M199.899 300.999c30.311 0 54.883-5.015 54.883-11.202 0-6.188-24.572-11.203-54.883-11.203-30.311 0-54.883 5.015-54.883 11.203 0 6.187 24.572 11.202 54.883 11.202z"
                fill="#F0F6FC"
            />
            <path
                d="M121.941 151.111l5.331-14.295 1.643.632-5.217 14.42-1.592-.058-.165-.699z"
                fill="#2C2C2C"
            />
            <path
                d="M213.985 276.306c-6.303 2.014-8.958-.95-8.958-.95s-.07-5.359 5.359-7.4c5.152-1.927 9.452 2.068 9.88 2.481l.033.033s.472 3.664-6.314 5.836zM185.476 280.326c-6.637 2.121-9.433-1.001-9.433-1.001s-.074-5.643 5.643-7.792c5.425-2.03 9.953 2.178 10.404 2.612l.035.035c.001 0 .498 3.859-6.649 6.146z"
                fill="#013005"
            />
            <path
                d="M122.995 172.002s-1.728-3.737-5.12-4.897c-3.392-1.16-4.418-3.927-4.508-8.39-.089-4.463.305-5.964 1.674-6.828 1.908-1.206 4.05-2.611 5.154-2.644 1.105-.034-1.305 2.242-.904 4.251.402 2.008 4.318 4.117 5.322 3.179 1.004-.937.201-2.51.569-4.016.08-.327.19-.618.313-.873.406-.84 1.604-.86 2.015-.023.298.605.558 1.41.585 2.402.067 2.443-.703 4.251.569 6.192 1.272 1.942-.695 5.346.305 6.706 1.001 1.361.934 2.432.934 2.432l-6.908 2.509z"
                fill="#593E01"
            />
            <path
                d="M205.389 170.854s21.409 17.142 20.742 25.527c-.667 8.385-8.861 14.578-8.861 14.578s-10.768-11.91-10.958-12.863c-.191-.953-.923-27.242-.923-27.242z"
                fill="#0EA59D" 
            />
            <path
                d="M172.947 165.922s.775 2.536.887 4.995c.112 2.459-.443 6.54-.443 6.54l16.149-3.298s-.167-5.239-.95-8.237c-.782-2.998-5.421-7.914-5.421-7.914l-9.066 2.662-1.156 5.252z"
                fill="#593E01"
            />
            <path
                d="M228.107 236.718s-3.67 16.293-12.743 20.553c-1.395.657-2.704.909-3.87.937a9.17 9.17 0 01-2.424-.245c-1.532-.361-2.47-.978-2.47-.978s-6.455 3.442-14.99 4.437c-.543.062-1.098.114-1.658.154-.926.068-1.875.109-2.835.109-2.293 0-4.666-.218-7.055-.749a29.55 29.55 0 01-2.333-.618c-14.292-4.43-33.85-15.116-33.158-23.011.211-2.435 1.669-4.07 3.824-5.128 7.959-3.91 25.487-.017 25.481-.017 0 0 20.862-.623 21.719-.451.858.165 18.335-2.585 18.718-3.008.382-.429 13.794 8.015 13.794 8.015z"
                fill="#F1F0B7"
            />
            <path
                d="M229.325 237.306s-4.86-3.144-17.723-2.43c-1.973.109-4.071.503-6.215 1.086h-.005c-11.829 3.236-24.938 12.349-24.938 12.349s-5.002-8.575-6.86-17.437c-1.383-6.597-1.024-12.366-.749-16.859.091-1.538.177-2.927.177-4.151 0-4.785-1.286-5.19-3.099-8.049-.634-1.006-2.298-.606-4.528.36-4.11 1.784-10.147 5.511-15.241 5.974-7.861.715-28.511-32.947-28.511-32.947s.137-3.499 3.642-6.711c3.504-3.219 6.574-2.79 6.574-2.79s11.714 14.572 17.455 20.987v.006a65.84 65.84 0 001.743 1.875c1.749 1.784 3.956 1.189 6.335-.469v-.005c3.836-2.676 8.106-8.124 11.582-10.771 5.62-4.288 15.911-7.238 15.911-7.238s13.006-3.67 19.438.046c6.22 3.596 8.432 14.281 9.862 23.257.051.303.097.6.143.897 1.429 9.147 4.573 11.434 9.29 20.152 4.716 8.718 5.717 22.868 5.717 22.868z"
                fill="#0EA59D" 
            />
            <path
                d="M172.834 214.014c.092-1.538.178-2.927.178-4.15 0-4.785-1.287-5.191-3.099-8.05-.635-1.006-2.298-.606-4.528.36.183-.28 5.225-7.826 6.723-4.459 1.521 3.431 1.904 5.62 4.093 7.147 2.196 1.526-3.367 9.152-3.367 9.152z"
                fill="#0D5B57"
            />
            <path
                d="M229.324 237.307s-4.859-3.145-17.723-2.43c-1.972.109-4.071.503-6.214 1.086 8.038-5.105 16.173-12.994 13.692-21.947-2.476-8.953-16.099-7.821-11.812-10.771 4.288-2.956 6.907-9.856 6.907-9.856.051.303.097.6.143.897 1.429 9.147 4.573 11.434 9.29 20.152 4.717 8.719 5.717 22.869 5.717 22.869z"
                fill="#219993"
            />
            <path
                d="M157.38 188.1c-.086.125-2.853 4.396-5.665 4.042-2.796-.355-2.43-5.231-2.413-5.449a65.9 65.9 0 001.744 1.876c1.749 1.783 3.956 1.188 6.334-.469z"
                fill="#0EA59D" 
            />
            <path
                d="M191.878 274.106c-.64-.6-5.077-4.55-10.365-2.572-5.717 2.149-5.642 7.792-5.642 7.792l-.012-.011c-.497-.503-16.55-16.723-24.571-26.287-1.91-2.276-3.287-4.345-4.288-6.18 5.351 4.304 14.693 11.177 22.336 13.469 5.243 1.572 9.479 2.304 12.532 2.635l3.076 3.425 6.934 7.729z"
                fill="#F1F0B7"
            />
            <path
                d="M191.231 287.902c0 3.39-1.4 6.546-7.18 7.009-.481.04-.989.063-1.532.063-1.727 0-2.962-.16-3.831-.44h-.005c-2.682-.875-1.767-2.898-.526-4.842.531-.829.954-1.824 1.257-2.99.635-2.441.726-5.626.097-9.588-.297-1.886.069-3.298.704-4.35 1.314-2.167 3.961-3.013 6.38-2.35a4.7 4.7 0 01.537.172c.989.366 1.961.949 2.458 1.852 1.247 2.27 1.641 13.892 1.641 15.464z"
                fill="#0E4915"
            />
            <path
                d="M178.683 294.533h-4.433c-1.623 0-2.639-1.795-1.764-3.162 1.25-1.955 3.414-4.181 6.93-4.67-.304 1.166-.727 2.161-1.258 2.99-1.241 1.944-2.156 3.968.525 4.842z"
                fill="#0E4915"
            />
            <path
                d="M220.23 270.395c-.606-.571-4.819-4.316-9.845-2.441-5.425 2.041-5.357 7.398-5.357 7.398s-.005 0-.011-.005c-.274-.28-5.626-5.689-11.548-11.949 3.727-.692 11.291-2.361 16.448-4.494l3.727 4.156 6.586 7.335z"
                fill="#F1F0B7"
            />
            <path
                d="M219.617 283.5c0 3.196-1.309 6.174-6.7 6.654a19.21 19.21 0 01-1.573.063c-1.641 0-2.812-.154-3.642-.423h-.005c-2.545-.829-1.675-2.75-.498-4.597.504-.789.91-1.732 1.195-2.841.601-2.315.692-5.339.092-9.102-.28-1.789.068-3.133.669-4.133 1.263-2.087 3.824-2.882 6.146-2.207.143.04.285.086.423.137.937.349 1.863.904 2.332 1.761 1.183 2.156 1.561 13.196 1.561 14.688z"
                fill="#0E4915"
            />
            <path
                d="M207.7 289.795h-4.829c-1.244 0-2.02-1.339-1.407-2.421 1.114-1.969 3.237-4.503 6.931-5.017-.288 1.108-.69 2.053-1.195 2.84-1.178 1.845-2.046 3.768.5 4.598z"
                fill="#0E4915"
            />
            <path
                d="M216.345 250.175c-1.349 3.402-3.859 6.306-5.58 8.027-2.509-.126-4.168-1.218-4.168-1.218l-.005-.006s6.935-4.447 8.93-10.788c1.406-4.487.714-7.58.554-8.198.309.892 2.293 7.089.269 12.183z"
                fill="#D5D38D"
            />
            <path
                d="M175.41 233.019h-3.502v-8.754a1.75 1.75 0 113.502 0v8.754z"
                fill="#CDE4F9"
            />
            <path
                d="M172.932 230.873a.29.29 0 01-.289-.289v-5.603a.29.29 0 11.578 0v5.603a.29.29 0 01-.289.289z"
                fill="#F79200"
            />
            <path
                d="M180.729 236.163s-2.241.903-6.575-.36c-3.379-.978-4.716-2.002-4.716-2.002s1.509-.88 5.968 0c4.465.887 5.323 2.362 5.323 2.362z"
                fill="#013005"
            />
            <path
                d="M174.385 230.873a.29.29 0 01-.289-.289v-5.603a.29.29 0 11.578 0v5.603a.29.29 0 01-.289.289z"
                fill="#F79200"
            />
            <path
                fill="#A4D2F4"
                d="M173.076 233.018H174.23911999999999V238.78287H173.076z"
            />
            <path
                d="M184.339 227.571l-8.131-2.188a2.109 2.109 0 111.095-4.072l8.132 2.188-1.096 4.072z"
                fill="#F1F0B7"
            />
            <path
                transform="rotate(15.058 177.916 225.854)"
                fill="#CDE4F9"
                d="M177.916 225.854H180.70837V242.20890000000003H177.916z"
            />
            <path
                d="M176.929 252.484c-.509.149-1.075.172-1.715.046-8.839-1.715-5.774-18.729-5.774-18.729s1.337 1.023 4.716 2.001c4.334 1.263 6.575.36 6.575.36s1.549 14.847-3.802 16.322z"
                fill="#0E4915"
            />
            <path
                d="M185.161 226.702l-.55-.147.511-1.899.549.148a.983.983 0 01-.51 1.898z"
                fill="#CDE4F9"
            />
            <path
                d="M176.929 252.484c-.509.149-1.075.172-1.715.046-8.839-1.715-5.774-18.729-5.774-18.729s1.091 14.51 7.489 18.683z"
                fill="#176B1F"
            />
            <path
                d="M163.998 164.266s6.748 3.823 15.072.054c-.617-1.355-.416-2.723.637-3.354.154-.087.282-.187.389-.295-1.013-1.281-2.046-2.824-3.093-4.681a55.904 55.904 0 01-1.63-3.166 68.768 68.768 0 01-2.227-5.232 70.786 70.786 0 01-.643-1.751c-1.436-1.569-3.032-3.32-4.28-5.05-1.569 2.106-3.554 5.392-4.225 9.223-1.174 6.706 0 14.252 0 14.252z"
                fill="#674B0B"
            />
            <path
                d="M169.35 133.358c.054 1.322.336 3.025.845 5.091a63.872 63.872 0 001.315 4.521c.295.912.63 1.864.993 2.871.201.57.415 1.153.643 1.75a68.366 68.366 0 002.227 5.232 55.904 55.904 0 001.63 3.166c1.047 1.858 2.079 3.4 3.093 4.681a21.9 21.9 0 001.817 2.046c2.147 2.14 4.105 3.085 5.648 3.267a9.298 9.298 0 005.023-.799c.47-.214.939-.476 1.389-.784a10.332 10.332 0 002.401-2.301c1.911-2.482 3.293-6.298 3.548-11.872a18.647 18.647 0 00-.301-4.246c-.986-5.211-4.199-9.826-9.049-12.24-1.589-.792-3.253-1.288-4.862-1.208-4.622.228-10.45-1.099-13.509-2.367-1.207-.497-2.569.241-2.783 1.529a8.028 8.028 0 00-.068 1.663z"
                fill="#F1F0B7"
            />
            <path
                d="M179.068 164.319c.765 1.657 2.75 3.307 5.889 3.582 5.701.502 9.014-3.502 9.014-3.502a9.346 9.346 0 01-6.412 1.583c-1.543-.181-3.501-1.126-5.647-3.266a21.902 21.902 0 01-1.818-2.046 1.798 1.798 0 01-.389.295c-1.053.63-1.254 1.999-.637 3.354zM174.112 158.009s1.589-1.59 2.89-2.019a55.233 55.233 0 01-1.63-3.166c-.254.53-.502.993-.811 2.106-.556 2.013-.449 3.079-.449 3.079zM166.513 137.826c.383.959.986 1.958 1.71 2.965 1.248 1.73 2.844 3.481 4.28 5.05-1.026-2.824-1.791-5.292-2.307-7.391-.51-2.066-.792-3.77-.845-5.091 0 0-1.107-.114-1.664-1.234-.556-1.114-.389-2.113-.389-2.113s-2.797 2.783-.785 7.814z"
                fill="#593E01"
            />
            <path
                d="M171.51 142.97c.295.912.63 1.865.993 2.871.201.57.415 1.153.643 1.75a68.196 68.196 0 002.227 5.232 55.254 55.254 0 001.63 3.166c1.047 1.858 2.079 3.4 3.093 4.681.616.779 1.22 1.463 1.817 2.046 2.147 2.14 4.105 3.085 5.648 3.267a9.306 9.306 0 005.023-.798 9.36 9.36 0 001.389-.785 10.312 10.312 0 002.401-2.301c1.911-2.482 3.293-6.298 3.548-11.872a18.635 18.635 0 00-.301-4.245c-3.267-2.885-10.384-8.485-15.89-8.659-4.756-.141-9.491 3.246-12.221 5.647z"
                fill="#F1F0B7"
            />
            <path
                d="M179.84 153.731a1.468 1.468 0 10.002-2.936 1.468 1.468 0 00-.002 2.936zM177.604 148.833a1.47 1.47 0 100-2.939 1.47 1.47 0 000 2.939z"
                fill="#F79200"
            />
            <path
                d="M125.498 151.785l-5.014 14.995a.04.04 0 01-.011.023 2.08 2.08 0 01-2.773 1.241l-1.852-.777a2.082 2.082 0 01-1.144-2.653c0-.006.006-.011.006-.017l5.746-14.956s1.149-.858 3.081 0c1.927.863 1.961 2.144 1.961 2.144z"
                fill="#0E4915"
            />
            <path
                d="M121.06 151.797c.242.111.359.39.267.641l-4.501 12.359a.511.511 0 01-.703.286l-.109-.052a.513.513 0 01-.257-.641l4.61-12.307a.512.512 0 01.693-.286zM123.887 152.533a.536.536 0 01.28.671l-4.709 12.929a.536.536 0 01-.735.299l-.113-.055a.535.535 0 01-.269-.67l4.822-12.875a.535.535 0 01.724-.299zM127.27 136.817l-.124-1.756 3.02 1.151-1.252 1.237-1.644-.632z"
                fill="#D3EDD5"
            />
            <path
                d="M181.867 262.953c-3.053-.332-7.289-1.064-12.531-2.635-7.644-2.293-16.986-9.165-22.337-13.47-3.27-6.02-2.43-9.541-2.43-9.541-.691 7.895 18.867 18.58 33.159 23.011.777.24 1.555.446 2.333.617l1.806 2.018zM209.915 258.903c-5.156 2.133-12.72 3.802-16.448 4.494-.611-.651-1.235-1.309-1.858-1.978 8.536-.995 14.99-4.437 14.99-4.437s.938.618 2.47.978l.846.943z"
                fill="#D5D38D"
            />
            <path
                d="M191.232 287.901c0 3.39-1.4 6.546-7.18 7.009-.875-.068-1.573-.2-2.122-.377h-.005c-2.682-.875-1.767-2.899-.526-4.842.531-.829.954-1.824 1.258-2.99.634-2.441.726-5.626.097-9.588-.298-1.887.068-3.299.703-4.351.726-1.2 1.863-1.995 3.138-2.349a4.757 4.757 0 01.538.171c.988.366 1.96.949 2.458 1.853 1.247 2.269 1.641 13.892 1.641 15.464zM219.617 283.497c0 3.196-1.309 6.175-6.7 6.655-.812-.069-1.458-.194-1.972-.36h-.006c-2.544-.829-1.675-2.75-.497-4.597.503-.789.909-1.732 1.194-2.841.601-2.316.692-5.34.092-9.102-.28-1.789.063-3.133.669-4.133.674-1.115 1.72-1.858 2.904-2.207.143.04.286.086.423.137.938.349 1.864.904 2.333 1.761 1.183 2.156 1.56 13.195 1.56 14.687z"
                fill="#0EA59D" 
            />
        </svg>
    )
}
  
  export default SvgComponent