import { AppSpan, AppText, Button, CustomContainer, FlexRow, GridContainer, Input } from 'src/style';
import {customStyles} from 'src/component/Table/style'
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { FiSearch } from 'react-icons/fi';
import '../Table/style.scss'
import helpers from 'src/utils/helpers';
import { BsFilter } from 'react-icons/bs';
import { FiTrash } from "react-icons/fi";
import {ReactComponent as NoDataIcon} from 'src/assets/img/no-data-icon.svg'

export const ServerSideTable: React.FC<any> = React.memo(({
  tableData, tableColumns, dataFetchMethod, totalRows, 
  pageNumber, callOnMount, customStyle, canSearch, 
  canFilter, canClear, tableTitle, shouldResetPagination,
  downloadAction
}) => {
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(pageNumber || 1);
    const [mockTableData, setMockTableData] = useState<any[]>([])

   useEffect(() =>{
      if (callOnMount) dataFetchMethod(pageNumber, perPage)
   }, [])

   useEffect(() => {
        setCurrentPage(1)
   }, [pageNumber, shouldResetPagination])

  useEffect(() => {
    setMockTableData(tableData)
  }, [tableData])

  function handleDataSearching (e: React.ChangeEvent<HTMLInputElement>) {
    const filteredData = helpers.searchTableData(tableData, e.target.value)
    setMockTableData(filteredData)
  }

   const handlePageChange = (page: any) => {
    dataFetchMethod(page, perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: any) => {
    dataFetchMethod(page, newPerPage);
    setPerPage(newPerPage);
  };


  return (
    <>
        <FlexRow 
            justifyContent='space-between' wrap='wrap' gap='2.5' bottomMargin='2.5'
            className='table-header-container'
        >
            { tableTitle &&
                <AppText textSize='3' color='#101828' fontWeight='600'>{tableTitle}</AppText>
            }
            <FlexRow 
                //width={!canClear ? '50' : '60'} 
                width='100' sizeUnit='%'
                gap='1.5' justifyContent='flex-end'
                className='search-box-wrapper'
            >
                { canSearch &&
                    <CustomContainer
                        radius='0.8' borderColor='#D0D5DD'
                        leftPadding='1.5' rightPadding='1.5'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                        className={!canClear ? 'search-box' : 'search-box-plus'}
                    >
                        <FlexRow>
                            <FiSearch style={{fontSize: '2rem', color: '#667085'}} />
                            <Input 
                                height='4' leftPadding='1' 
                                placeholder='Search' 
                                onChange={handleDataSearching} 
                            />
                        </FlexRow>
                    </CustomContainer>
                }
                { canFilter &&
                    <GridContainer 
                        width='10.2' height='4'
                        radius='0.8' borderColor='#D0D5DD'
                        onClick={canFilter}
                    >
                        <FlexRow width='auto' sizeUnit=''>
                            <BsFilter style={{fontSize: '2rem', color: '#344054', marginRight: '1rem'}} />
                            <AppSpan fontWeight='600' color='#344054' cursor='pointer'>Filters</AppSpan>
                        </FlexRow>
                    </GridContainer>
                }
                { canClear &&
                    <GridContainer 
                        width='11.8' height='4'
                        radius='0.8' borderColor='#D0D5DD'
                        onClick={canClear}
                    >
                        <FlexRow width='auto' sizeUnit=''>
                            <FiTrash style={{fontSize: '1.7rem', color: '#344054', marginRight: '1rem'}} />
                            <AppSpan fontWeight='600' color='#344054' cursor='pointer'>Clear All</AppSpan>
                        </FlexRow>
                    </GridContainer>
                }
                { downloadAction &&
                    <Button
                        width='11'
                        titleSize='1.4' fontWeight='600'
                        height='4' radius='0.8'
                        // hoverBgColor='#fff' borderColor='#0D968F' 
                        // hoverColor='#0D968F'
                        color='#344054' borderColor='#D0D5DD'
                        bgColor='#fff'
                        onClick={downloadAction}
                    >
                        Download
                    </Button>
                }
            </FlexRow>
        </FlexRow>
        {
            (tableData && tableData?.length > 0) ?
            <div className='table-wrapper'>
                <DataTable
                    columns={tableColumns}
                    data={mockTableData?.length ? mockTableData : tableData}
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    // paginationResetDefaultPage={shouldResetPagination}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    customStyles={customStyle ? customStyles  : undefined}
                />
            </div>
            :
            <GridContainer
                minHeight='25' borderColor='#E2E8F0' radius='0.3' padding='2'
            >
                <CustomContainer width='auto' sizeUnit=''>
                    <NoDataIcon className='icon-component' />
                    <AppText align='center' color='#101828' textSize='1.6' fontWeight='600'>No Record Found</AppText>
                </CustomContainer>
            </GridContainer>
        }    
    </>
  )
})

