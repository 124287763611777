import { RiCloseFill } from "react-icons/ri"
import { 
    AbsoluteContainer, AppText, Button, CustomContainer, FlexColumn, 
    FlexRow, Form, FormGroup, SidePopupContainer 
} from "src/style"
import {ReactComponent as Banner} from 'src/assets/img/remote-banner.svg'
import { handleTypeNumberControl } from "src/utils/utility";


export const InstitutionSelection: React.FC<any> = ({onInput, formData, handleNext, close}) => {

    function handleProceed (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        handleNext()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Remote
                </AppText>
                <CustomContainer>
                    <CustomContainer
                        maxHeight='11.5' radius='0.46' bottomMargin="1"
                        height='11.5' topMargin='2'
                    >
                        <Banner style={{width: '100%'}} />
                    </CustomContainer>
                    <Form onSubmit={handleProceed}>
                        <FormGroup>
                            <label>Institution Code</label>
                            <input 
                                placeholder="Enter Code" 
                                name='ibInstitutionCode'
                                type='number'
                                value={formData.ibInstitutionCode}
                                onChange={onInput}
                                onKeyDown={handleTypeNumberControl}
                                onKeyUp={handleTypeNumberControl}
                                required
                            />
                        </FormGroup>
                            
                        <FlexRow justifyContent='flex-end'>
                            <Button
                                width='8.8'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8'
                                bottomMargin='1' hoverColor='#0D968F'
                                hoverBgColor='#fff' borderColor='#0D968F'
                            >
                                Proceed
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </FlexColumn>
        </>
    )
}