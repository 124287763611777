import './style.scss'
import { useState, useContext, useEffect, useRef } from "react";
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { AbsoluteContainer, Button, CustomContainer, FlexColumn, SidePopupContainer } from "src/style"
// import { SketchPicker } from 'react-color'
import { Color, ColorPicker as MyPicker, useColor } from "react-color-palette"; 
import "react-color-palette/lib/css/styles.css"; 


export const ColorPicker: React.FC<any> = ({output, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState('')
    const [color, setColor] = useColor("hex", "#121212"); 
    const isPhoneRef = useRef(false)

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => { document.body.style.overflow = 'auto' }
    }, [])

    useEffect(() => {
        isPhoneRef.current = window.matchMedia('(max-width: 320px)').matches
    }, [])

    function handleColorSelection (color: any) {
        setColor(color)
        setInput(color.hex)
        output(color.hex)
    }

    function handleSubmit () {
        notifier.show('Advertisement Published Successfully', null, 'success')
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="color-palette-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='0.2' right='0.7'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2rem'}} />
                    </Button> 
                </AbsoluteContainer>
                {/* <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    New Notification
                </AppText> */}
               <CustomContainer>
                    {/* <SketchPicker
                        onChange={handleColorSelection}
                    /> */}
                    <MyPicker  width={isPhoneRef.current ? 210 : 256} height={isPhoneRef.current ? 100 : 150} color={color} onChange={handleColorSelection} />
               </CustomContainer>
            </FlexColumn>
        </>
    )
}