import { Feed, ImgDimensions, PaginationInfo, ValidatorResponse } from "src/model"
import imageCompression from 'browser-image-compression';
import jsSHA from 'jssha'
import { appSettings } from "src/provider/config/constant";
import utility from "./utility";

const inputValidator = (data: any, exemptedPropertes?: string[], includedProperties?: string[]): ValidatorResponse => {
    let result = <ValidatorResponse>{ isValidated: true }

    if (includedProperties?.length) {
        exemptedPropertes = Object.keys(data)
    }
    for (let key in data) {
        if (!data[key] && (!exemptedPropertes?.includes(key) || includedProperties?.includes(key))) {
            result.isValidated = false;
            result.message = `Input: Kindly provide "${utility.splitCamelCase(key, true)}"`
            break;
        }
    }

    return result
} 

const validateEmail = (email: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

const isValidFileType = (filename: string, acceptedExtensions?: Array<string>) => {
    if (!acceptedExtensions) {
        acceptedExtensions = ['svg', 'png', 'jpg', 'jpeg']
    }
    let result = true
    let splittedFileName = filename.split('.')
    let extension = splittedFileName[splittedFileName.length - 1].toLowerCase()
    if (!acceptedExtensions.includes(extension)) result = false
        return result
}

const isValidUrl = (url: string) => {
    url = url.trim();

    const validUrlRegex = /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}(:\d{1,5})?(\/.*)?$/i;
    const validIPRegex = /^(https?:\/\/)(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?(\/.*)?$/i;
    
    const isValidURL = validUrlRegex.test(url) || validIPRegex.test(url);

    console.log({url, isValidURL});

    return isValidURL;
}

const searchTableData = (tableData: any, searchText: string) => {
    let result = []

    for (let obj of tableData) {
        for (let key in obj) {
            if (String(obj[key]).toLowerCase().includes(searchText.toLowerCase())) {
                result.push(obj)
                break;
            }
        }
    }

    if (result.length < 1) {
        result = tableData
    }

    return result
}

const addSerialNumberToItems = (data: any, paginationInfo: PaginationInfo, shouldPullData = false, pullDataKey?: string) => {
    let result = []
    const {pageNumber = 1, pageSize = 10} = paginationInfo
    let index = ((pageNumber - 1) * pageSize) + 1
    
    for (let item of data) {
        if (shouldPullData && pullDataKey) {
            item = {...item, ...item[pullDataKey]}
        }
        item.sn = index
        result.push(item)
        index++
    }

    return result
}

const splitDateTime = (data: string) => {
    let [date, time] = data.split('T')
    let [year, month, day] = date.split('-')
    let [hours, minutes] = time.split(':')

    month = (month.length > 1 && month[0] === '0') ? month[1] : month
    const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const formattedHours = (hours.length > 1 && hours[0] === '0') ? hours[1] : hours

    return {
        date: `${day}-${monthShort[Number(month)-1]}-${year}`,
        slashedDate: `${day}/${month}/${year}`,
        time: `${hours}:${minutes}`,
        timeEnv: `${Number(formattedHours) < 12 ? 'am' : 'pm'}`,
        completeTime: `${hours}:${minutes}${Number(formattedHours) < 12 ? 'am' : 'pm'}`
    }

}

const handleDate = (date: string) => {
    let formattedDate = splitDateTime(date);
    return `${formattedDate?.date} ${formattedDate?.time}${formattedDate?.timeEnv}`
}

const getInputValidDate = () => {
    let dtToday = new Date()

    let [month, day, year]: Array<any> =  [
        dtToday.getMonth() + 1,
        dtToday.getDate(),
        dtToday.getFullYear()
    ]

    if (month < 10) month = `0${month}`
    if (day < 10) day = `0${day}`

    return `${year}-${month}-${day}`
}

export const compressImage = async (image: any, maxSizeMB = 0.39) => {
    const file = image //e.target.files[0];

    try {
        const options = {
            maxSizeMB,
            //maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        console.log({compressedFile})

        return compressedFile
    } catch (err: any) {
        console.error('Error compressing image:', err.message);
        return ''
    }
};

const convertFileToBase64 = (file: any, includeDimensions = false) => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL: any = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            baseURL = baseURL.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', '')

            if (includeDimensions) {
                const img = new Image();
                img.onload = () => {
                    const dimensions = { width: img.width, height: img.height };
                    resolve({ baseURL, dimensions });
                };

                img.src = reader.result as string;

            } else {
                resolve(baseURL);
            }
        };
    });
};

const getFileExtension = (file: string) => {
    const splittedFileName = file.split('.')
    let extension = splittedFileName[splittedFileName.length - 1].toLowerCase()

    return extension === 'svg' ? 'png' : extension
}

const hashString = (text: string) => {
    const shaObj = new jsSHA("SHA-512", "TEXT", { numRounds: 64});
    shaObj.update(text)
    return shaObj.getHash("B64")
}

const setSessionExpiryTime = () => {
    const date = new Date()
    //date.setTime( date.getTime() - new Date().getTimezoneOffset() * 60 * 1000 );
    date.setMinutes(date.getMinutes() + appSettings.SESSION_EXPIRATION_LENGTH)
    localStorage.setItem('session-expiry-time', date.toString())
}

const validatePhoneNo = (phoneNo: string) => {
    if (phoneNo.startsWith('+234') || phoneNo.startsWith('234')) {
        phoneNo = phoneNo.replace('+', '')
        phoneNo = phoneNo.replace('234', '0')
    }
    if (phoneNo.length === 10) phoneNo = `0${phoneNo}`
    if ((['070', '080', '081', '090', '091'].includes(phoneNo.substring(0, 3))) && phoneNo.length === 11)
        return true;
    else return false
}

const validatePhoneNoAll = (phoneNo: string) => {
    if (phoneNo.length >= 7 && phoneNo.length <= 14) return true
    else return false
}

const validatePassword = (password: string): boolean => {
    var regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
}

const getFileSize = (base64: string) => {
    const sizeInBytes = 4 * Math.ceil((base64.length / 3)) * 0.5624896334383812;
    return (sizeInBytes/1000).toFixed(1);
}

const checkFeedAvailablity = (feeds: Feed[], type: string) => {
    let isAvailable = false
    for (let feed of feeds) {
        if ((type === 'web' && feed.webImageUrl) || (type === 'mobile' && feed.mobileImageUrl)) {
            isAvailable = true
            break;
        }
    }

    return isAvailable
}

const sortItemsWIthNumber = (items: any[], sortBy = 'count', order = 'desc') => {
    items = items.sort((a, b) => {
        if (order === 'desc') return b[sortBy] - a[sortBy]
        else return a[sortBy] - b[sortBy]
    });

    return items
}

const sortItemsWIthString = (items: any[], sortBy = 'name', order = 'desc') => {
    if (order != 'desc') {
        items.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    }
    else items.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));

    return items
}

const validateConfigUrls = (data: any) => {
    let message = ''
    if (!isValidUrl(data.termsAndConditionsUrl)) {
        message = 'Kindly provide a valid Terms and Conditions URL'
    }

    if (!isValidUrl(data.privacyPolicyUrl)) {
        message = 'Kindly provide a valid Privacy Policy URL'
    }

    if (data.loanPolicyUrl && !isValidUrl(data.loanPolicyUrl)) {
        message = 'Kindly provide a valid Loan Policy URL'
    }

    return message
}

const validateImgResolution = (imageName: string, imageSize: ImgDimensions) => {
    let message = '', isValidResolution = true

    if (imageName === 'logo' && ((imageSize.width < 128 || imageSize.width > 512) || (imageSize.height < 128 || imageSize.height > 512))) {
        message = 'The uploaded image dimensions are invalid. Kindly provide an image with dimensions within 128 and 512'
        isValidResolution = false
    }

    if (imageName === 'favIcon' && ((imageSize.width != imageSize.height) || (imageSize.width < 300 || imageSize.width > 512))) {
        message = 'The uploaded image dimensions are invalid. Kindly provide an image with square dimensions within the range of 300 and 512'
        isValidResolution = false
    }

    return {isValidResolution, message}
}

export default {
    inputValidator,
    validateEmail,
    isValidFileType,
    searchTableData,
    addSerialNumberToItems,
    splitDateTime,
    handleDate,
    getInputValidDate,
    compressImage,
    convertFileToBase64,
    getFileExtension,
    hashString,
    setSessionExpiryTime,
    validatePhoneNo,
    validatePhoneNoAll,
    validatePassword,
    getFileSize,
    checkFeedAvailablity,
    sortItemsWIthNumber,
    sortItemsWIthString,
    isValidUrl,
    validateConfigUrls,
    validateImgResolution
}