import { RiCloseFill } from "react-icons/ri"
import { AbsoluteContainer, AppText, Button, FlexColumn, FlexRow, Form, FormGroup, GridContainer, SidePopupContainer } from "src/style"
import {ReactComponent as PasswordIcon} from 'src/assets/img/secure-password-icon.svg'


export const UserIdentification: React.FC<any> = ({onInput, formData, handleNext, close}) => {

    function handleProceed (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        handleNext()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Change Password
                </AppText>
                <GridContainer topMargin="2">
                    <PasswordIcon />
                </GridContainer>
                <Form onSubmit={handleProceed} topMargin='1'>
                    <FormGroup>
                        <label>Email </label>
                        <input 
                            placeholder="Enter Email or Username" 
                            name='email'
                            value={formData.email}
                            onChange={onInput}
                            required
                        />
                    </FormGroup>
                    
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='8.8'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Proceed
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}