import { useState, useContext } from "react";
import helpers from "src/utils/helpers"
import { RiCloseFill } from "react-icons/ri"
import ApiContext from "src/provider/API/call-service";
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { AbsoluteContainer, AppLabel, AppSpan, AppText, Button, FlexColumn, FlexRow, Form, FormGroup, SidePopupContainer } from "src/style"
import UploadIcon from 'src/assets/img/upload-icon.svg'


export const AddNewNotification: React.FC<any> = ({getNotifications, close}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState({title: '', message: '', imagePath: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
       setInput({...input, [e.target.name]: e.target.value})
    }

    // async function handleFile (e: React.ChangeEvent<HTMLInputElement>) {
    //     if (e.target.files instanceof FileList) {
    //         if(!helpers.isValidFileType(e.target.value)) return notifier.show('Only SVG, PNG, and JPEG files are allowed')

    //         setInput({...input, imagePath: URL.createObjectURL(e.target.files[0])})
    //     }
    // }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const response = await API.sendNotification(input)
        if (response) {
            if (getNotifications) await getNotifications()
        }    
        close()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    New Notification
                </AppText>
                <Form onSubmit={handleSubmit} topMargin='2'>
                    <FormGroup>
                        <label>Notification Title</label>
                        <input 
                            placeholder="Enter Title" 
                            name='title'
                            value={input.title}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>
                    <FormGroup >
                        <label>Notification Message</label>
                        <textarea 
                            placeholder="Enter Message" 
                            name='message'
                            value={input.message}
                            onChange={handleInput}
                            required
                        />
                    </FormGroup>
                    {/* <FlexColumn 
                        borderColor='#EAECF0' radius='0.8' minHeight='12.6' topMargin='1'
                        padding='1.5'
                    >
                        <img src={UploadIcon} />
                        <input type="file" name='primaryLogo' onChange={handleFile} className="logo-input" id="primary-logo" />
                        <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='primary-logo'>Click to upload</AppLabel>
                        <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>(800 X 300) Preferred</AppSpan>
                    </FlexColumn> */}
                    <FlexRow justifyContent='flex-end' topMargin='2'>
                        <Button
                            width='15'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Send Notification
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}