import { useState, useContext } from "react";
import { RiCloseFill } from "react-icons/ri"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from "src/provider/API/call-service";
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, Form, FormGroup, SidePopupContainer, Switch } from "src/style"
import helpers from "src/utils/helpers";


export const CreateInstitution: React.FC<any> = ({getInstitutions, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({
        institutionName: '', firstName: '', lastName: '', username: '',
        email: '', phoneNumber: '', institutionCode: '', alias: '',
        isBaasInstitution: false
    })

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!helpers.validatePhoneNoAll(input.phoneNumber)) return notifier.show('Kindly provide a valid phone number', "Validation Response")
        if (!helpers.validateEmail(input.email)) return notifier.show('Kindly provide a valid email', "Validation Response")

        const response = await API.createInstitutionAndAdmin({...input, role: 'Admin'})
        if (response) {
            if (getInstitutions) getInstitutions()
            close()
        }    
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Create Institution
                </AppText>
                <Form onSubmit={handleSubmit}>
                    <CustomContainer topMargin='2'>
                        <AppSpan textSize="1.6" fontWeight="600" fontFamily="Gilmer-Bold">Institution Details</AppSpan>
                        <FormGroup topMargin='1.5'>
                            <label>Institution name</label>
                            <input 
                                placeholder="Enter institution name" 
                                name='institutionName'
                                value={input.institutionName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Institution BankOne code</label>
                            <input 
                                placeholder="Enter code" 
                                name='institutionCode'
                                value={input.institutionCode}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                        <FormGroup>
                            <label>Institution Alias</label>
                            <input 
                                placeholder="Enter alias" 
                                name='alias'
                                value={input.alias}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup> 
                        <FlexRow gap="1.5" alignItems="flex-start" justifyContent="flex-start" bottomMargin="2">
                            <AppSpan fontWeight="600" color="#344054">Baas Institution ?</AppSpan>
                            <Switch
                                radius='1.17' bgColor='#E7E7E7' 
                                checkedBgColor='#0D968F' checkedSliderBgColor="#ffffff"
                            >
                                <input 
                                    type="checkbox" 
                                    checked={input.isBaasInstitution}
                                    onChange={(e) => setInput({...input, isBaasInstitution: e.target.checked})} 
                                />
                                <div ></div>
                                <span></span>
                            </Switch>
                        </FlexRow>  
                    </CustomContainer>
                    <CustomContainer topMargin="1">
                        <AppSpan textSize="1.6" fontWeight="600" fontFamily="Gilmer-Bold">Admin Details</AppSpan>
                        <FormGroup topMargin="1.5">
                            <label>First name</label>
                            <input 
                                placeholder="Enter first name" 
                                name='firstName'
                                value={input.firstName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                        <FormGroup>
                            <label>Last name</label>
                            <input 
                                placeholder="Enter last name" 
                                name='lastName'
                                value={input.lastName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                        <FormGroup>
                            <label>Username</label>
                            <input 
                                placeholder="Enter username" 
                                name='username'
                                value={input.username}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                        <FormGroup>
                            <label>Email</label>
                            <input 
                                placeholder="Enter email" 
                                name='email'
                                type='email'
                                value={input.email}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                        <FormGroup>
                            <label>Phone number</label>
                            <input 
                                placeholder="Enter phone number" 
                                name='phoneNumber'
                                type='number'
                                value={input.phoneNumber}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>  
                    </CustomContainer>
                    
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='8.1'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Submit
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}