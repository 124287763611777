import { CSSProperties } from 'react'
import { capsuleAccent } from './accents'

export const capsule = (
  value: any,
  dot?: boolean,
  size?: 'small' | 'normal'
) => {
  const accent = capsuleAccent(value)

  const colors = () => {
    switch (accent) {
      case 'recova':
        return { accent: { backgroundColor: '#F9F1E9', color: '#F4A82B' }, dot: { backgroundColor: '#F4A82B' } }
      case 'amber':
        return { accent: { backgroundColor: '#FEF0C7', color: '#B54708' }, dot: { backgroundColor: '#B54708' } }
      case 'purple':
        return { accent: { backgroundColor: '#F9F5FF', color: '#5925DC' }, dot: { backgroundColor: '#5925DC' } }
      case 'blue':
        return { accent: { backgroundColor: '#E9F3FB', color: '#175CD3' }, dot: { backgroundColor: '#175CD3' } }
      case 'sky-blue':
        return { accent: { backgroundColor: '#F0F9FF', color: '#026AA2' }, dot: { backgroundColor: '#026AA2' } }
      case 'red':
        return { accent: { backgroundColor: '#FEF3F2', color: '#B42318' }, dot: { backgroundColor: '#B42318' } }
      case 'green':
        return { accent: { backgroundColor: '#ECFDF3', color: '#027A48' }, dot: { backgroundColor: '#027A48' } }
      case 'grey':
        return { accent: { backgroundColor: '#6a6a6a2e', color: '#6f6666' }, dot: { backgroundColor: '#6a6a6a2e' } }
      default:
        return { accent: { backgroundColor: '#6a6a6a2e', color: '#6f6666' }, dot: { backgroundColor: '#6a6a6a2e' } }
    }
  }

  const capsuleStyle: CSSProperties = size === 'small'
    ? { fontSize: '9px', paddingLeft: '4px', paddingRight: '4px', borderRadius: '4px' }
    : { fontSize: '11px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '16px' }

  return (
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px', ...capsuleStyle, ...colors().accent }}>
        {!!dot && (
          <p
            style={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              ...colors().dot,
            }}
          ></p>
        )}
        {value}
      </span>
    </span>
  )
}
