import './style.scss'
import { useState, useContext, useEffect } from 'react';
import utility from 'src/utils/utility'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext, { AppInfoProvider } from 'src/provider/state-manager/appInfoProvider';
import { 
    AbsoluteContainer,
    AppSpan,
    AppText, Button, CustomContainer, FlexRow, GridContainer, ScreenContainer 
} from 'src/style';
import { IoTrashOutline } from "react-icons/io5";
import {ReactComponent as NoDataIcon} from 'src/assets/img/no-data-icon.svg'
import { AddNewSlide } from 'src/popup'
import { UserTypes } from 'src/model';

export const OnboardingSlides = () => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {userType}} = useContext(AppInfoContext)
    const [slides, setSlides] = useState([])
    const [userRole, setUserRole] = useState('')
    const [showAddNewSlide, setShowAddNewSlide] = useState(false)

    useEffect(() => {
        if (userType) setUserRole(userType)
    }, [userType])

    useEffect(() => {
        handleFetchSlides()
    }, [])

    async function handleFetchSlides() {
        const response = await API.getOnboardingSlides()
        if (response)setSlides(response)
    }

    async function handleDelete (id:  number) {
        const response = await API.deleteOnboardingSlide(id)
        if (response) handleFetchSlides()
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer>
                    <FlexRow 
                        justifyContent='space-between' alignItems='center' 
                    >
                        <AppText textSize='1.9' color='#101828' fontWeight="700">
                            Onboarding Slider
                        </AppText>
                        { userRole === UserTypes.ADMIN &&
                            <Button
                                width='10.4' leftMargin='2'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8' hoverColor='#0D968F'
                                hoverBgColor='#fff' borderColor='#0D968F'
                                onClick={() => setShowAddNewSlide(true)}
                            >
                                New Slide
                            </Button>
                        }
                    </FlexRow>
                    <CustomContainer topMargin='1' bottomMargin='2'>
                        { slides?.length ?
                            slides.map((item: any, index: number) => {
                                return (
                                    <CustomContainer 
                                        minHeight='14' padding='2' radius='0.8'
                                        borderColor='#D0D5DD' key={index} topMargin='2'
                                    >
                                        <CustomContainer 
                                            width='90' sizeUnit='%'
                                        >
                                            <AppText textSize='1.8' color='#101828' fontWeight='700'>{item.title}</AppText>
                                            <AppText color='#101828' textSize='1.6' fontWeight='300' topMargin='2'>
                                                {item.body}
                                            </AppText>
                                        </CustomContainer>
                                        { userRole === UserTypes.ADMIN &&
                                            <AbsoluteContainer top='1.2' right='2'>
                                                <AppSpan 
                                                    textSize="2" color='#111111' hoverColor="#F80000" 
                                                    onClick={() => handleDelete(item.id)}
                                                >
                                                    <IoTrashOutline />
                                                </AppSpan>
                                            </AbsoluteContainer>
                                        }
                                    </CustomContainer>
                                )
                            })
                            :
                            <GridContainer 
                                minHeight='28' padding='2' radius='0.8'
                                borderColor='#D0D5DD' topMargin='2'
                            >
                                <CustomContainer width='auto' sizeUnit=''>
                                    <NoDataIcon className='icon-component' />
                                    <AppText align='center' color='#101828' textSize='1.6' fontWeight='600'>No Slider</AppText>
                                </CustomContainer>
                            </GridContainer>
                        }
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { showAddNewSlide &&
                <AddNewSlide 
                    getSlides={handleFetchSlides}
                    close={() => setShowAddNewSlide(false)}
                />
            }
        </>
    )
}

export default utility.routeData('onboarding-slides', 'Onboarding Slides', <OnboardingSlides/>)