import { useState } from "react";
import { RiCloseFill } from "react-icons/ri"
import { 
    AbsoluteContainer, AppSpan, AppText, Button, FlexColumn, 
    FlexRow, Form, FormGroup, GridContainer, SidePopupContainer 
} from "src/style"
import {ReactComponent as PasswordIcon} from 'src/assets/img/secure-password-icon.svg'
import { BsEye, BsEyeSlash } from "react-icons/bs";


export const NewPassword: React.FC<any> = ({onInput, formData, handleProceed, close}) => {
    const [showPassword, setShowPassword] = useState({newPassword: false, confirmPassword: false})

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        handleProceed()
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Change Password
                </AppText>
                <GridContainer topMargin="2">
                    <PasswordIcon />
                </GridContainer>
                <Form onSubmit={handleSubmit} topMargin='1'>
                    <FormGroup>
                        <label>New password</label>
                        <input 
                            placeholder="XXXXXXX" 
                            name='newPassword'
                            type={showPassword.newPassword ? 'text' : 'password'}
                            value={formData.newPassword}
                            onChange={onInput}
                            required
                        />
                        { !showPassword.newPassword ?
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, newPassword: true})}
                                >
                                    <BsEyeSlash/>
                                </AppSpan>
                            </AbsoluteContainer>
                            :
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, newPassword: false})}
                                >
                                    <BsEye/>
                                </AppSpan>
                            </AbsoluteContainer>
                        }
                    </FormGroup> 
                    <FormGroup>
                        <label>Confirm password</label>
                        <input 
                            placeholder="XXXXXXX" 
                            name='confirmPassword'
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            value={formData.confirmPassword}
                            onChange={onInput}
                            required
                        />
                        { !showPassword.confirmPassword ?
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, confirmPassword: true})}
                                >
                                    <BsEyeSlash/>
                                </AppSpan>
                            </AbsoluteContainer>
                            :
                            <AbsoluteContainer bottom='1.2' right='1'>
                                <AppSpan 
                                    color='rgba(0, 0, 0, 0.5)' textSize='1.7' cursor='pointer'
                                    onClick={() => setShowPassword({...showPassword, confirmPassword: false})}
                                >
                                    <BsEye/>
                                </AppSpan>
                            </AbsoluteContainer>
                        }
                    </FormGroup> 
                    
                    <FlexRow justifyContent='flex-end'>
                        <Button
                            width='12.9'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                        >
                            Save Changes
                        </Button>
                    </FlexRow>
                </Form>
            </FlexColumn>
        </>
    )
}