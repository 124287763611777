import { useContext, useState } from "react"
import ApiContext from "src/provider/API/call-service";
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { InstitutionSelection, NewPassword, OTPVerification, UserIdentification } from "src/popup"
import { ForgotPasswordInput } from "src/model"
import helpers from "src/utils/helpers"

export const ForgotPassword: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [view, setView] = useState('institution-selection')
    const [input, setInput] = useState<ForgotPasswordInput>({
        ibInstitutionCode: '', otp: '', email: '', 
        newPassword: '', confirmPassword: ''
    })

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.name === 'otp' && e.target.value.length > 6) return false
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleEmailVerification () {
        const response = await API.sendForgotPasswordOtp(input.ibInstitutionCode, input.email)
        if (response) setView('otp')
    }

    async function handleOtpVerification () {
        const {ibInstitutionCode, email, otp} = input

        if (!otp || otp.length != 6) return notifier.show('Kindly provide a valid otp')

        const response = await API.validateForgotPasswordOtp(ibInstitutionCode, email, otp)
        if (response) setView('new-password')
    }

    async function handleSubmit () {
        if (input.confirmPassword != input.newPassword) return notifier.show('Password Confirmation did not match', 'Validation Response')
        if (!helpers.validatePassword(input.newPassword)) return notifier.show('Please ensure your password has a special character, a lowercase letter, a number and an uppercase letter', 'Validation Response')

        await API.resetAdminUserPassword(input)
        close()
    }
    
    return (
        <>
            { view === 'institution-selection' ?
                <InstitutionSelection
                    close={close}
                    onInput={handleInput}
                    formData={input}
                    handleNext={() => setView('indentification')}
                />
                    :  view === 'indentification' ?
                            <UserIdentification 
                                close={() => setView('institution-selection')}
                                onInput={handleInput}
                                formData={input}
                                handleNext={handleEmailVerification}
                            />
                            : view === 'otp' ?
                                <OTPVerification 
                                    close={() => setView('indentification')}
                                    onInput={handleInput}
                                    formData={input}
                                    handleNext={handleOtpVerification}
                                />
                                : 
                                    view === 'new-password' ?
                                        <NewPassword
                                            close={close}
                                            onInput={handleInput}
                                            formData={input}
                                            handleProceed={handleSubmit} 
                                        />
                                        : null
                            
            }
        </>
    )
}