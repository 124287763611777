import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import { AppSpan, FixedContainer, FlexRow, GridContainer, SidePopupContainer } from "src/style";
import { FiLogOut } from "react-icons/fi";
import { BsOption } from "react-icons/bs"
import { ImShift } from "react-icons/im"

export const LogoutUI: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {logout} = useContext(AppInfoContext)

    return (
        <>
            <SidePopupContainer zIndex="7" onClick={close} bgColor='transparent' />
            <FixedContainer top="9" right="7" className="logout-btn-wrapper" zIndex="8">
                <GridContainer 
                    leftPadding="1.5" rightPadding="1.5" width="24" radius="0.8"
                    height="4" bgColor="#ffffff" 
                    shadow="0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);"
                    className="logout-container" onClick={() => logout()}
                >
                    <FlexRow justifyContent="space-between">
                        <FlexRow justifyContent="flex-start" width="auto" sizeUnit="">
                            <AppSpan textSize="1.7">
                                <FiLogOut/>
                            </AppSpan>
                            <AppSpan leftPadding="1" cursor="pointer" >Log out</AppSpan>
                        </FlexRow>
                        <FlexRow width="auto" sizeUnit="">
                            <AppSpan textSize="1.1"><BsOption/></AppSpan>
                            <AppSpan textSize="1.1"><ImShift/></AppSpan>
                            <AppSpan textSize="1.1" cursor="pointer">Q</AppSpan>
                        </FlexRow>
                    </FlexRow>
                </GridContainer>
            </FixedContainer>
        </>
    )
}