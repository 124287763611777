
export const capsuleAccent = (status: string): CapsuleAccent => {
  switch (status) {
    case 'Successful':
    case 'Success':
    case 'Sent':
    case 'Confirmed':
    case 'Linked':
    case 'Approved':
    case 'Approve':
    case 'Active':
    case 'Granted':
    case 'Completed':
      return 'green'
    case 'Pending':
    case 'Processing':
    case 'Initiated':
    case 'AwaitingConfirmation':
    case 'Awaiting Confirmation':
    case 'AwaitingDisbursement':
      return 'amber'
    case 'Update':
    case 'Disbursed':
    case 'Validated':
    case 'Valid':
    case 'Recalled':
    case 'Secondary':
      return 'purple'
    case 'Failed':
    case 'Expired':
    case 'Cancelled':
    case 'Decline':
    case 'Rejected':
      return 'red'
    case 'Collection':
    case 'New':
    case 'Create':
      return 'blue'
    case 'Recovery':
    case 'Recova':
    case 'Primary':
      return 'recova'
    case 'All':
      return 'grey'
    default:
      return 'grey'
  }
}

type CapsuleAccent =
  | 'recova'
  | 'amber'
  | 'purple'
  | 'blue'
  | 'sky-blue'
  | 'red'
  | 'green'
  | 'grey'
