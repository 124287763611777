import './style.scss'
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, GridContainer } from 'src/style'
import FallBackCircles from 'src/assets/svg/fallback-circles'
import FallBackIllustrator from 'src/assets/svg/fallback-illustrator'
import {ReactComponent as Logo} from 'src/assets/img/app-logo-black.svg'
import access from 'src/utils/localAccess'

export const ErrorFallback: React.FC<any> = ({ error, componentStack, resetErrorBoundary }) => {

    function handleLogout () {
        access.getLogoutHandler()()
        window.location.reload()
    }

    return (
        <GridContainer 
            height='100' hUnit='%' padding='2.5'
            style={{overflow: 'hidden'}}
        >
            <AbsoluteContainer top='2.5' left='4' className='error-header-logo'>
                <Logo className='icon-component' />
            </AbsoluteContainer>
            <AbsoluteContainer top='4' left='-3' className='fallback-circles-block'>
                <FallBackCircles />
            </AbsoluteContainer>
            <FlexRow 
                width='auto' sizeUnit='' gap='4'
                className='error-component-flex'
            >
                <CustomContainer width='45' className='error-content-block'>
                    <AppText 
                        color='#07122F' textSize='5' fontWeight='700'
                        style={{lineHeight: '6rem'}} bottomMargin='2'
                    >
                        Something Went Wrong!
                    </AppText>
                    <AppSpan color='#07122F' textSize='1.8'>Let go back home</AppSpan>
                    <CustomContainer width='auto' sizeUnit='' topMargin='2.5'>
                        <Button 
                            width='12.6' height='4' bgColor='#0D968F'
                            borderColor='#0D968F' hoverBgColor='#ffffff' 
                            hoverColor='#0D968F' titleSize='1.4'
                            onClick={handleLogout}
                        >
                            Home
                        </Button>
                    </CustomContainer>
                </CustomContainer>
                <CustomContainer width='auto' sizeUnit=''>
                    <FallBackIllustrator className='fallback-illustrator' style={{objectFit: 'scale-down'}} />
                </CustomContainer>
            </FlexRow>
        </GridContainer>
    )
}
