import {
    AbsoluteContainer,
    AppText,
    Button,
    CustomContainer,
    FlexColumn,
    FlexRow,
    Form,
    FormGroup,
    FormGroupWithIcon,
    SidePopupContainer,
} from "src/style";
import { RiCloseFill } from "react-icons/ri";
import { NewFaqProps, UserTypes } from "src/model";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import { useContext, useEffect, useState } from "react";
import ApiContext from "src/provider/API/call-service";


export const NewFaq = ({ close, getFAQs, isEdit, faqId, editQuestion, editAnswer }: NewFaqProps) => {

    const { API } = useContext(ApiContext);
    const { info: { userType },} = useContext(AppInfoContext);
    const [input, setInput] = useState({ question: "", answer: "" });

    useEffect(() => {
        setInput({ question: editQuestion || "", answer: editAnswer || "" });
    }, [editQuestion, editAnswer]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const charLength = e.target.name === "question" ? 50 : 100;
        if (e.target.value.length <= charLength)
            setInput({ ...input, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) => {
        e.preventDefault();
        const payload = {
            ...(isEdit && faqId ? { id: faqId } : null),
            ...input,
        };

        if (userType === UserTypes.SUPER_ADMIN) {
            const response = await API.saveAppFAQ(payload);
            if (response) {
                if (getFAQs) await getFAQs();
            }
            close();
        } else if (userType === UserTypes.ADMIN) {
            const response = await API.saveInstitutionFAQ(payload);
            if (response) {
                if (getFAQs) await getFAQs();
            }
            close();
        }
    };


    return (
        <>
            <SidePopupContainer>
                <FlexColumn
                    className="new-ads-container"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <AbsoluteContainer top="1.5" right="1.5">
                        <Button
                            titleSize="2"
                            fontWeight="500"
                            width="3.4"
                            shadow="none"
                            height="3.4"
                            radius="0.4"
                            bgColor="#ffffff"
                            hoverBgColor="#FEF3F2"
                            borderColor="none"
                            color="#000000"
                            hoverColor="#B42318"
                            onClick={close}
                        >
                            <RiCloseFill style={{ fontSize: "2.3rem" }} />
                        </Button>
                    </AbsoluteContainer>
                    <AppText
                        color="#101828"
                        textSize="1.8"
                        fontWeight="700"
                        fontFamily="Gilmer-Bold"
                    >
                        New FAQ
                    </AppText>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup topMargin="2">
                            <label>Freqently Asked Question</label>
                            <input
                                placeholder="Enter Question (max characters length is 50)"
                                name="question"
                                value={input.question}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Answer</label>
                            <textarea
                                placeholder="Enter Answer Text (max characters length is 100)"
                                name="answer"
                                value={input.answer}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>

                        <FlexRow justifyContent="flex-end" topMargin="2">
                            <Button
                                width="10.9"
                                titleSize="1.4"
                                fontWeight="600"
                                height="4"
                                radius="0.8"
                                bottomMargin="1"
                                hoverColor="#0D968F"
                                hoverBgColor="#fff"
                                borderColor="#0D968F"
                            >
                                Save FAQ
                            </Button>
                        </FlexRow>
                    </Form>
                </FlexColumn>
            </SidePopupContainer>
        </>
    );
};
